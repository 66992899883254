<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondCharts.vue
Description: This file contains UI showing all the graphs on the selected pond in ponds of overview section
-->
<template>
  <el-row class="pond-charts">
    <el-row :gutter="10">
      <el-col class="chart pg-charts__data-do-ph" :span="12">
        <pg-status-graph></pg-status-graph>
      </el-col>
      <el-col class="chart pond-charts__feed-cumulative" :span="12">
        <pond-schedule-graph></pond-schedule-graph>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col class="chart pond-charts__growth-vs-biomass" :span="12">
        <pond-awg-biomass-graph></pond-awg-biomass-graph>
      </el-col>
      <el-col class="chart pond-charts__feed-hourly" :span="12">
        <pond-hourly-graph></pond-hourly-graph>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col class="chart pond-charts__manual-feed" :span="12">
        <manual-feed-graph></manual-feed-graph>
      </el-col>
      <el-col class="chart pm-charts__feed-cumulative" :span="12">
        <pm-schedule-graph></pm-schedule-graph>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="chart">
        <hourly-feed-vs-response-graph></hourly-feed-vs-response-graph>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="chart">
        <ocf-and-fg-graph></ocf-and-fg-graph>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import pondHourlyGraph from "./graphs/pondHourlyGraph";
import pondScheduleGraph from "./graphs/pondScheduleGraph";
import pmScheduleGraph from "./graphs/pmScheduleGraph";
import pgStatusGraph from "./graphs/pgStatusGraph";
import hourlyFeedVsResponseGraph from "./graphs/hourlyFeedVsResponseGraph";
import ocfAndFgGraph from "./graphs/ocfAndFgGraph";
import manualFeedGraph from "./graphs/manualFeedGraph";
import pondAwgBiomassGraph from "./graphs/pondAwgBiomassGraph";

import { mapGetters } from "vuex";
export default {
  components: {
    pondHourlyGraph,
    pondScheduleGraph,
    pmScheduleGraph,
    pgStatusGraph,
    hourlyFeedVsResponseGraph,
    pondAwgBiomassGraph,
    manualFeedGraph,
    ocfAndFgGraph
  },
  data: function() {
    return {
      // pondId: "",
      pondDetails: null
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      pondId: "getSelectedPondId"
    })
  },
  methods: {}
};
</script>

<style lang="scss">
.pond-charts {
  .card-title {
    line-height: 1.15;
    flex-shrink: 13;
    display: inline;
  }
  & > .el-row {
    display: flex;
    flex-direction: row;
    .er-card {
      height: 100%;
      // padding: 10px;
      .el-card__header {
        padding: 5px 10px;
      }
    }
    margin-bottom: 10px;
  }
  .chart {
    // border: 1px solid blue;
    b {
      font-weight: 500;
    }
  }
  .el-input__inner {
    @include small-text;
  }
  .el-input__icon.el-range__close-icon {
    display: none;
  }
  .el-range-editor {
    @include responsiveProperty(flex-basis, 50%, 50%, 30%);
  }
  .el-range-editor.el-input__inner {
    @include responsiveProperty(padding, 1px 10px, 3px 10px, 3px 10px);
  }
}
</style>
