<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: samplesTable2.vue
Description: This file is the part of the abw page. It is a popup useful for collecting the details of the sampling which is useful calculating the abw value
-->
<template>
  <el-popover
    v-model="visible"
    :popper-class="`sample-calculator ${popoverClass || ''}`"
    :width="400"
    :title="$t('Comn_samples')"
    :offset="popoverOffset || 0"
    @show="handleOpenABWCalculator"
  >
    <el-col>
      <el-row>
        <layout-toolbar
          align="middle"
          justify="space-between"
          type="flex"
          gap="5"
          class="sample-calculator-row"
          v-for="(sample_row, index) in popoverData.samples"
          :key="index"
        >
          <el-input-number
            :controls="false"
            :min="0"
            size="mini"
            class="input-sample-wt"
            :placeholder="$t('Comn_weight_g')"
            :title="$t('ABW_samples_weight_g')"
            v-model="sample_row.weight"
          ></el-input-number>

          <el-input-number
            :controls="false"
            :min="1"
            size="mini"
            class="input-sample-size"
            :placeholder="$t('Comn_samples')"
            :title="$t('ABW_total_samples')"
            v-model="sample_row.no_of_shrimps"
          ></el-input-number>
          <div class="sample-action-row__btns">
            <er-button
              btnType="delete"
              :showIcon="true"
              size="mini"
              :disabled="popoverData.samples.length < 2"
              @click="handleRemoveSample(index, popoverData)"
            ></er-button>
            <er-button
              btnType="add"
              :showIcon="true"
              size="mini"
              @click="handleAddNewSample(popoverData, index)"
            ></er-button>
          </div>
        </layout-toolbar>
      </el-row>
      <template slot="name_header"></template>
      <el-row type="flex" justify="end" class="abw-popper__btn-actions-row">
        <er-button
          btnType="calculate"
          size="mini"
          :showLabel="true"
          @click="updateFields(popoverData, 'calculate')"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          @click="handleClosePopper(popoverData, 'cancel')"
          size="mini"
        ></er-button>
      </el-row>
    </el-col>
    <template slot="reference">
      <slot name="reference" v-bind:item="popoverData">
        <er-button
          slot="reference"
          size="mini"
          btnType="add"
          type="text"
          :disabled="isDisabled"
          :showLabel="true"
          :showIcon="true"
        ></er-button>
      </slot>
    </template>
  </el-popover>
</template>

<script>
import { Samples } from "@/model/abw";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [errorHandlerMixin],
  props: ["popoverData", "popoverOffset", "popoverClass", "isDisabled"],
  data: function () {
    return {
      errorMessage: "",
      cancelObjCopy: {},
      visible: false
    };
  },
  computed: {
    tableColumns: function () {
      return {
        name: {
          label: this.$t("Comn_name"),
          prop: "name"
        },
        weight: {
          label: this.$t("Comn_weight_g"),
          prop: "weight"
        },
        no_of_shrimps: {
          label: this.$t("Comn_samples"),
          prop: "no_of_shrimps"
        }
      };
    },
    elTableProps: function () {
      return {
        showSummary: true,
        summaryMethod: this.getSummaries
      };
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t("Comn_total");
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)
            .toFixed(2);
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    handleOpenABWCalculator() {
      this.cancelObjCopy = this.$lodash.cloneDeep(this.popoverData);
      // abwObj.isPopUpVisible = true;
    },
    handleClosePopper: function (abwObj, event = undefined) {
      abwObj.modified = true;
      this.visible = false;
      if (event === "cancel") {
        this.$emit("cancelled", this.cancelObjCopy);
      }
      // this.location_name = "";
    },
    handleAddNewSample: function (abwObj, index) {
      abwObj.modified = true;
      abwObj.samples.splice(index + 1, 0, new Samples());

      // this.updateFields(abwObj);
    },
    handleRemoveSample: function (indexSample, abwObj) {
      abwObj.modified = true;
      abwObj.samples.splice(indexSample, 1);
    },
    updateFields: async function (abwObj, event = "") {
      abwObj.modified = true;
      this.calcABW(abwObj);
      this.calcWeightGain(abwObj);
      if (event === "calculate") {
        try {
          if (abwObj.samples.length === 0) {
            throw {
              type: "FAIL_TO_SAVE",
              errors: [
                { message: this.$t("ABW_total_samples_weight_not_be_zero") }
              ]
            };
          }
          const errors = [];
          abwObj.samples.forEach((sample, index) => {
            const inSamplesStr = `${this.$t("Comn_in")} ${this.$t(
              "Comn_samples"
            )}  ${index + 1}`;
            if (!+sample.no_of_shrimps) {
              errors.push({
                message: `${this.$t("Comn_must_not_be_empty", {
                  field: this.$t("Comn_samples")
                })} ${inSamplesStr}`
              });
            }
            if (!+sample.weight) {
              errors.push({
                message: `${this.$t("Comn_must_not_be_empty", {
                  field: this.$t("Comn_weight_g")
                })} ${inSamplesStr}`
              });
            }
          });
          if (errors.length > 0) {
            throw {
              type: "FAIL_TO_SAVE",
              errors: errors
            };
          }
          abwObj.sample_total_weight = abwObj.samples.reduce(
            (acc, curr) => (acc += curr.weight),
            0
          );
          this.visible = false;
          this.$emit("calculated", abwObj);
        } catch (err) {
          await this.ehm__errorMessages(err, true);
          this.$emit("cancelled", this.cancelObjCopy);
        }
      }
    },
    calcABW: function (abwObj) {
      let totalWeight = 0;
      let totalSamples = 0;
      abwObj.samples.forEach((sample) => {
        sample.abw = sample.weight / sample.no_of_shrimps;
        totalWeight += sample.weight;
        totalSamples += sample.no_of_shrimps;
      });
      if (totalWeight) {
        abwObj.abw = totalWeight / totalSamples;
      } else {
        abwObj.abw = 0;
      }
      return abwObj.abw;
    },
    calcWeightGain: function (abwObj) {
      abwObj.weight_gain = +(abwObj.abw - abwObj.last_abw).toFixed(2);
      this.errorMessage = "";
      return abwObj.weight_gain;
    }
  }
};
</script>

<style lang="scss">
.sample-calculator {
  width: 300px !important;
  .el-popover__title {
    color: #233a73;
    padding: 5px;
  }
  .el-input__inner {
    @include responsiveProperty(height, 24px, 26px, 28px);
  }
  .input-sample-wt {
    width: 100px;
  }
  .input-sample-size {
    width: 100px;
  }
  .sample-calculator-row:not(:first-child) {
    margin-top: 5px;
  }
  .sample-calculator-row:last-of-type {
    margin-bottom: 10px !important;
  }
  .sample-action-row__btns {
    .er-button.el-button--mini:not(.is-circle) {
      padding: 3px 5px;
    }
  }
}
</style>
