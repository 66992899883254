<template>
  <er-dialog
    :visible="dialogVisibleStatus"
    width="30%"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @close="handleDialogClose"
    :title="`${$t('Comn_update')} ${$t('ABW_abw')}`"
    custom-class="pond-abw-tab-dialog"
  >
    <el-form
      size="small"
      :ref="formName"
      :rules="rules"
      :model="editABWObj"
      :hide-required-asterisk="true"
    >
      <el-form-item
        :label="$t('Comn_pond_name')"
        prop="pond_id"
        :required="true"
      >
        <el-col :span="20">
          <er-select
            filterable
            size="small"
            v-model="editABWObj.pond_id"
            :disabled="true"
            value-key="_id"
          >
            <el-option
              v-for="(pond, pond_id) in getPondIdPondMap"
              :key="pond_id"
              :value="pond._id"
              :label="pond.title"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('ABW_abw')" prop="abw">
        <el-col :span="20">
          <el-input-number
            size="small"
            :controls="false"
            :min="0"
            :precision="2"
            v-model="editABWObj.abw"
            @change="handleChangeInABW($event, editABWObj)"
          ></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('Comn_awg')" prop="awg">
        <el-col :span="20">
          <el-input-number
            size="small"
            :controls="false"
            :precision="2"
            v-model="editABWObj.awg"
            disabled
          ></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="$t('Comn_survival_percentage')"
        prop="survival_percentage"
      >
        <el-col :span="20">
          <!-- <template v-if="editABWObj.harvested_abw"> -- </template> -->
          <el-input-number
            v-model="editABWObj.survival_percentage"
            size="small"
            :controls="false"
            :max="100"
            :min="0"
          ></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('Comn_date_and_time')">
        <el-col :span="20">
          <el-input :value="editABWObj.dateStr" disabled> </el-input>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('ABW_samples_total_weight')">
        <el-col :span="20">
          <el-input
            size="small"
            disabled
            v-model="editABWObj.sample_total_weight"
          >
            <template slot="append">
              <samples-table2
                :popover-data="editABWObj"
                @cancelled="handleSampleTableCancel"
              >
                <template slot="reference">
                  <er-button size="mini" type="primary" :label="true">
                    {{ $t("Comn_edit") }}
                  </er-button>
                </template>
              </samples-table2>
            </template>
          </el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <layout-toolbar slot="footer" justify="center" class="dialog-footer">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="isLoading"
        @click="frm__validateFormType2(handleSaveEdits)"
        :disabled="isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleDialogClose"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import samplesTable2 from "./samplesTable2";
import formRulesMixin from "@/mixins/formRulesMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [
    formRulesMixin,
    errorHandlerMixin,
    datesHandlerMixin,
    notificationMixin
  ],
  components: {
    samplesTable2
  },
  data() {
    return {
      editABWObj: {
        pond_id: "",
        date: "",
        abw: 0,
        awg: 0,
        sample_total_weight: 0,
        survival_percent: 100,
        samples: [],
        searchPond: ""
      },
      errMessagesObj: {
        pond_id: "",
        date: "",
        abw: 0,
        awg: 0,
        sample_total_weight: 0,
        survival_percent: 100,
        samples: []
      },
      formName: "editAbwForm"
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      getSelectedPondName: "getSelectedPondName"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      dialogVisibleStatus: "getABWRecActionDialogVisibleStatus",
      getLoadingComponents: "getLoadingComponents",
      getABWRecActionDialogModel: "getABWRecActionDialogModel"
    }),
    isLoading() {
      return this.getLoadingComponents.has("ABW_FORM_DIALOG");
    },
    rules: function() {
      const abwTrans = this.$t("ABW_abw");
      const survivalPercentTrans = this.$t("Comn_survival_percentage");
      return {
        abw: [
          {
            type: "number",
            required: true,
            message: this.$t("Comn_must_not_be_empty", {
              field: abwTrans
            }),
            trigger: "blur"
          },
          {
            type: "number",
            min: 0,
            message: this.$tc("Comn_must_greater_than", 2, {
              field: abwTrans,
              threshold: 0
            }),
            trigger: "blur"
          }
        ],
        survival_percentage: [
          {
            type: "number",
            required: true,
            message: this.$t("Comn_must_not_be_empty", {
              field: survivalPercentTrans
            }),
            trigger: "blur"
          },
          {
            type: "number",
            min: 0,
            message: this.$tc("Comn_must_greater_than", 2, {
              field: survivalPercentTrans,
              threshold: 0
            }),
            trigger: "blur"
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      changeDialogVisibleStatus: "changeABWRecActionDialogVisibilityStatus",
      removeComponentFromLoading: "removeComponentFromLoading",
      addComponentToLoading: "addComponentToLoading",
      fetchManualABWRecords: "fetchManualABWRecords"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),

    async handleSaveEdits() {
      try {
        this.addComponentToLoading("ABW_FORM_DIALOG");
        const editObj = this.editABWObj;
        if (editObj.harvested_abw) {
          delete editObj.survival_percentage;
        }
        const aggSamples = editObj.samples.reduce(
          (acc, sample) => {
            if (!isFinite(+sample.no_of_shrimps) || !isFinite(sample.weight)) {
              return acc;
            }
            acc[0] += sample.weight;
            acc[1] += sample.no_of_shrimps;
            return acc;
          },
          [0, 0]
        );
        let { samples, ...payload } = editObj;
        if (aggSamples[0] > 0 && aggSamples[1] > 0) {
          samples = samples.map(sample => {
            const abw = editObj.abw;
            const weight = abw * sample.no_of_shrimps;
            return {
              ...sample,
              abw: abw,
              weight
            };
          });
          payload.samples = samples;
        }
        await this.$store.dispatch("pond/saveABWValues", [payload]);
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_update_success_msg",
            count: 0,
            child: {
              item: this.$t("ABW_abw"),
              source: this.getSelectedPondName
            }
          }
        });
        this.mixPanelEventGenerator({ eventName: "Ponds - ABW - Table Data - Edit" });
        this.removeComponentFromLoading("ABW_FORM_DIALOG");
        this.handleDialogClose("refresh");
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.editABWObj.isPopUpVisible = false;
      }
    },
    handleOpenPopover(abwObj) {
      if (!abwObj.isPopUpVisible) {
        this.$set(abwObj, "isPopUpVisible", true);
      } else {
        this.handleClosePopper(abwObj);
      }
    },
    async handleDialogClose(event) {
      this.editABWObj.isPopUpVisible = false;
      this.frm__resetForm();
      this.changeDialogVisibleStatus(false);
      if (event === "refresh") {
        await this.fetchManualABWRecords();
      }
    },
    handleClosePopper: function(abwObj) {
      abwObj.isPopUpVisible = false;
      // abwObj.modified = true;
      // this.location_name = "";
    },
    handleSampleTableCancel(abwObj) {
      this.editABWObj = abwObj;
    },
    calcWeightGain: function(latest_abw, last_abw) {
      let weight_gain = last_abw;
      weight_gain = Number((latest_abw - (last_abw || 0)).toFixed(2));
      return weight_gain;
    },
    handleChangeInABW(changedABWValue, abwRecord) {
      abwRecord.modified = true;
      if (!isFinite(+changedABWValue)) return;
      abwRecord.awg = this.calcWeightGain(changedABWValue, abwRecord.last_abw);
      const agg = abwRecord.samples.reduce(
        (acc, x) => {
          if (!isFinite(+x.no_of_shrimps) || !isFinite(x.weight)) return acc;
          acc[0] += x.no_of_shrimps;
          acc[1] += x.weight;
          return acc;
        },
        [0, 0]
      );
      if (agg[0] === 0 && agg[1] === 0) return;
      abwRecord.samples.forEach(sample => {
        sample.abw = changedABWValue;
        sample.weight = changedABWValue * sample.no_of_shrimps;
      });
      abwRecord.sample_total_weight = abwRecord.samples.reduce(
        (acc, curr) => acc + curr.weight,
        0
      );
    },
    handleDialogOpen() {
      this.addComponentToLoading("ABW_FORM_DIALOG");
      this.editABWObj = this.$lodash.cloneDeep(this.getABWRecActionDialogModel);
      this.removeComponentFromLoading("ABW_FORM_DIALOG");
    }
  }
};
</script>

<style lang="scss" scoped>
.er-dialog::v-deep .pond-abw-tab-dialog {
  border-radius: 10px;
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(width, 210px, 300px, 350px);
  }
}
</style>
