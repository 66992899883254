<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondsSelectWithDetails.vue
Description: This file contians UI components used to display pond selection and display of pond selected in ponds page in overview section
-->
<template>
  <el-card
    shadow="never"
    class="card-pond-select-with-details"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row
      class="pond-details-row"
      align="middle"
      justify="space-between"
      :key="pondId"
    >
      <el-col :span="3" class="item-container">
        <div class="item-container__body">
          <el-row class="item-header">{{ $t("Comn_pond_name") }}</el-row>
          <el-row class="item-body">
            <er-select
              size="mini"
              :value="pondSelected"
              :filterable="true"
              value-key="_id"
              styleType="no-border"
              @change="handleChangeInPond"
            >
              <el-option-group
                v-for="(group, key) in getPondGroupByStatus"
                :key="key"
                :label="$t(statusLangMap[key])"
              >
                <el-option
                  v-for="(pond, key) in group"
                  :key="`pond-${key}`"
                  :value="pond"
                  :label="pond.title"
                >
                  <span style="white-space: pre-wrap"> {{ pond.title }}</span>
                </el-option>
              </el-option-group>
            </er-select>
          </el-row>
        </div>
      </el-col>
      <el-col :span="8" class="item-container">
        <div class="item-container__body">
          <el-row class="item-header normal-text">{{
            $t("Pond_pls_stocked")
          }}</el-row>
          <el-row class="item-body">
            <b
              v-upm__nounits.digitPrecision.formatNumber="getPLStockedAndUnits"
            ></b>
          </el-row>
        </div>
        <div class="item-container__body">
          <el-row class="item-header normal-text">{{
            $t("Ponds_water_spread_area")
          }}</el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(
                (this.pondSelected || { size: undefined }).size,
                this.getPreferredUnits.pond_area_units
              )
            "
            v-upm__units.formatEmpty.digitPrecision="getTotalSizeAndUnits"
          >
          </el-row>
        </div>
        <div class="item-container__body" :span="9">
          <el-row class="item-header normal-text">{{ $t("Comn_doc") }}</el-row>
          <el-row class="item-body" :data-units="$t('Comn_days_new')">
            {{ ftm__formatEmpty(daysOfCulture) }}
          </el-row>
        </div>
      </el-col>
      <el-col :span="10" class="item-container">
        <div class="item-container__body">
          <el-row class="item-header normal-text">
            {{ $t("Comn_survival_rate") }}
          </el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(netAbwSummaryvalues.survival_percentage, '%')
            "
          >
            {{ ftm__formatEmpty(netAbwSummaryvalues.survival_percentage) }}
          </el-row>
        </div>
        <div :span="9" class="item-container__body">
          <el-row class="item-header normal-text">
            {{ $t("Comn_biomass") }}
          </el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(
                netAbwSummaryvalues.biomass,
                this.getPreferredUnits.biomass_units
              )
            "
            v-upm__units.digitPrecision.formatEmpty.truncateThousandStr="
              getBiomassAndUnits
            "
          >
          </el-row>
        </div>
        <div class="item-container__body">
          <el-row class="item-header normal-text">
            {{ $t("Comn_net_feed") }}
          </el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(
                netFeedSummaryValues.total_dispensed_feed,
                this.upm__feedUnits
              )
            "
            v-upm__units.formatEmpty.digitPrecision="getnetFeedSummaryValues"
          >
          </el-row>
        </div>
        <div :span="9" class="item-container__body">
          <el-row class="item-header normal-text">{{ $t("ABW_abw") }}</el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(
                netAbwSummaryvalues.abw,
                this.getPreferredUnits.abw_and_growth_units
              )
            "
            v-upm__units.formatEmpty.digitPrecision="getnetAbwSummaryvalues"
          >
          </el-row>
        </div>
        <div :span="9" class="item-container__body">
          <el-row class="item-header normal-text">{{ $t("Comn_awg") }}</el-row>
          <el-row
            class="item-body"
            :data-units="
              getSuffix(
                netAbwSummaryvalues.awg,
                this.getPreferredUnits.abw_and_growth_units
              )
            "
            >{{
              ftm__formatEmpty(ftm__digitPrecision(netAbwSummaryvalues.awg, 1))
            }}</el-row
          >
        </div>
      </el-col>
      <el-col :span="3" class="item-container" :key="$i18n.locale">
        <div class="item-container__body">
          <el-row class="item-header">{{ $t("Comn_current_culture") }}</el-row>
          <el-row class="item-body">
            <er-select
              v-if="getCultures.length > 0"
              :value="getCurrentCulture"
              value-key="harvest_id"
              @change="handleChangeInCulture"
              styleType="no-border"
            >
              <el-option
                v-for="(cultivation, index) in getCultures"
                :key="index"
                :label="getStartString(cultivation)"
                :value="cultivation"
                >{{ getCultivationName(cultivation) }}</el-option
              >
            </er-select>

            <el-tag
              type="info"
              size="mini"
              :disable-transitions="true"
              v-else
              >{{ $t("PM_culture_not_started_msg") }}</el-tag
            >
          </el-row>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function () {
    return {
      loading: false
    };
  },

  computed: {
    ...mapGetters("pondDetails", {
      pondsObj: "getPondIdPondMap",
      getPondGroupByStatus: "getPondGroupByStatus",
      pondId: "getSelectedPondId",
      getSelectedPond: "getSelectedPondWithDeviceDetails",
      getUserCountryName: "getUserCountryName",
      getCultures: "getAllCultureOfPond",
      getCurrentCulture: "getCurrentCulture",
      getCurrentCulturePLStocked: "getCurrentCulturePLStocked",
      getSelectedNetFeedSummary: "getSelectedNetFeedSummary",
      getSelectedPondAbwSummary: "getSelectedPondAbwSummary"
    }),
    ...mapGetters("user", {
      getUserCountryCode: "getUserCountryCode",
      getUserCountryName: "getUserCountryName",
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString",
      getDeviceTypesEnabledForUser: "getDeviceTypesEnabledForUser"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      getSSAbwNearestToToday: "getSSAbwNearestToToday"
    }),
    getnetAbwSummaryvalues() {
      const netAbwSummaryValues = {
        value: this.netAbwSummaryvalues.abw || 0,
        previousunits: this.upm__backendUnits.abw_and_growth_units,
        units: this.getPreferredUnits.abw_and_growth_units,
        modifiersParams: {
          digitPrecision: [2, "round"],
          formatEmpty: [true, "--"]
        },
        ui: { units: false, value: false }
      };
      return netAbwSummaryValues;
    },
    getnetFeedSummaryValues() {
      const netFeedSummaryValues = {
        value:
          this.ftm__digitPrecision(
            this.netFeedSummaryValues.total_dispensed_feed,
            0
          ) || 0,
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__feedUnits,
        modifiersParams: {
          digitPrecision: [0],
          truncateThousandStr: [2, "round"],
          formatEmpty: [true, "--"]
        },
        ui: { units: false, value: false }
      };
      return netFeedSummaryValues;
    },
    statusLangMap() {
      return {
        ACTIVE: "Comn_active",
        INACTIVE: "Comn_inactive"
      };
    },
    getPLStockedAndUnits() {
      const totalPLStockedAndUnits = {
        value: this.getCurrentCulturePLStocked || 0,
        // previousunits: this.upm__backendUnits.pond_area_units,
        // units: this.getPreferredUnits.pond_area_units,
        previousunits: "",
        units: "",
        modifiersParams: {
          formatNumber: [this.getUserCountryCode],
          digitPrecision: [0]
        }
      };
      return totalPLStockedAndUnits;
    },
    getTotalSizeAndUnits() {
      const totalsizeandUnits = {
        value: +(this.pondSelected || { size: 0 }).size,
        previousunits: this.pondSelected.units ? this.pondSelected.units : this.upm__backendUnits.pond_area_units,
        units: this.getPreferredUnits.pond_area_units,
        modifiersParams: { digitPrecision: [2], formatEmpty: [true, "--"] },
        ui: { units: false, value: false }
      };
      return totalsizeandUnits;
    },
    getBiomassAndUnits() {
      const biomassAndUnits = {
        value:
          this.ftm__digitPrecision(this.netAbwSummaryvalues.biomass, 0) || 0,
        previousunits: this.upm__backendUnits.biomass_units,
        units: this.getPreferredUnits.biomass_units,
        modifiersParams: {
          truncateThousandStr: [2],
          formatEmpty: [true, "--"],
          digitPrecision: [2]
        },
        ui: { units: false, value: false }
      };

      return biomassAndUnits;
    },
    getSuffix() {
      return (quantity, units) => {
        if (isNaN(+quantity)) {
          return "";
        }
        return units;
      };
    },
    daysOfCulture: function () {
      const value = this.getCurrentCulture.doc;
      return value > 0
        ? value + 1
        : this.getCurrentCulture.start_date && value === 0
        ? "Today"
        : "--";
    },
    netAbwSummaryvalues: function () {
      return this.getSelectedPondAbwSummary;
    },
    netFeedSummaryValues: function () {
      return this.getSelectedNetFeedSummary;
    },
    pondSelected() {
      console.log("oo--oo", this.$lodash.cloneDeep(this.getSelectedPond));
      return this.$lodash.cloneDeep(this.getSelectedPond);
    },
    getLocaleObj: function () {
      return { locale: this.$i18n.locale };
    }
  },
  methods: {
    ...mapActions("pondDetails", {
      changeSelectedPondId: "changeSelectedPondId",
      changeCulture: "changeCulture"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getStartString(culture) {
      return (
        culture.start_date &&
        this.dhm__formatByTZOnUTCISOWithLocale(
          culture.start_date,
          this.upm__getFormatDateString
        )
      );
    },
    getCultivationName(culture) {
      const dateFormat = (date) =>
        this.dhm__formatByTZOnUTCISOWithLocale(
          date,
          this.upm__getFormatDateString
        );
      const harvest_id = culture.harvest_id;
      if (harvest_id === 0) {
        return this.getStartString(culture);
      }
      return (
        culture.start_date &&
        `${dateFormat(culture.start_date)} - ${dateFormat(culture.end_date)}`
      );
    },
    async handleChangeInPond(pond) {
      this.loading = true;
      try {
        await this.changeSelectedPondId(pond._id);
        this.mixPanelEventGenerator({ eventName: "Ponds - Pond Name Selection" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleChangeInCulture(cultureObj) {
      this.loading = true;
      try {
        await this.changeCulture(cultureObj);
        this.mixPanelEventGenerator({ eventName: "Ponds - Previous Culture Selection" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.card-pond-select-with-details {
  .el-card__body {
    // @include responsiveProperty(height, 65px, 75px, 90px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );

    .pond-details-row {
      width: 100%;
      .item-container {
        display: flex;
        flex-direction: row;
        color: #061058;
        justify-content: space-between;
        &:not(:last-child) {
          border-right: 1px solid #ececec;
        }
        &:not(:first-child) {
          padding-left: 15px;
        }
        &:not(:last-child) {
          padding-right: 10px;
        }
        .item-container__body {
          padding: 10px 0px;
          .item-header {
            opacity: 0.5;
            // @include responsiveProperty(max-width, 75px, 160px, 250px);
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
          .value-units,
          .item-body {
            font-weight: 600;
            height: 30px;
            line-height: 30px;
            .el-input__inner {
              font-weight: 600;
              line-height: 30px;
              padding-left: 0px;
              padding-right: 23px;
              height: 30px;
            }
            .el-input__icon {
              line-height: 30px;
            }
            &:after {
              content: attr(data-units);
              display: inline;
              margin-left: 5px;
              @include responsiveProperty(
                font-size,
                $app_font_size_small,
                $app_font_size_1,
                $app_font_size_2
              );
            }
          }
        }
      }
    }
  }
  .el-input__inner {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .select-pond,
  .culture-select {
    .el-select {
      width: 100%;
      .el-input__inner {
        font-weight: bold;
        color: #3f98cb;
      }
    }
  }
  .el-row--flex {
    display: flex;
    align-items: center;
  }

  .divisional-line {
    display: inline-block;
    border: 1px solid #ccc;
    margin: 0 20px;
    height: 63px;
  }
  .el-popper .popper__arrow::after {
    content: " ";
    border-width: 0px;
  }
  .el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom: none;
  }
}
</style>
