<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stOcfAndFgGraph.vue
Description: This file is the chart component that displays feed dispensed in each cycle by the shrimptalk. Here x axis values ranges from 0 to 24 hours and y axis is the amount of feed dispensed in grams
-->
<template>
  <er-card
    v-loading="getStGraphLoading"
    element-loading-background="white"
    class="st-ocf-and-fg-graph"
    ref="st-ocf-and-fg-graph"
  >
    <el-row slot="header">
      <layout-toolbar justify="start" align="center">
        <p class="card-title">
          {{ $t("Comn_ocf_name") }}
        </p>
      </layout-toolbar>
    </el-row>
    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import { stOCFGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, chartMixin, datesHandlerMixin],
  data: function() {
    return {
      dataViewOptions: "Raw Data",
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: { ...stOCFGraphConfig },
      loading: false,
      listShrimpTalks: [],
      STResponseData: [],
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      },
      timeFormatObject: {
        "HH:mm": "%H:%M",
        "hh:mm a": "%l:%M %p"
      }
    };
  },
  watch: {
    getStGraphLoading: function(newValue) {
      if (!newValue) this.handleChangeInSt();
    }
  },
  mounted() {
    this.chm__registerResizeObserver("st-ocf-and-fg-graph");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("st-ocf-and-fg-graph");
  },
  computed: {
    ...mapGetters("pondDetails", {
      getStHourlyFeedGraph: "getStHourlyFeedGraph",
      getStOCFGraphData: "getStOCFGraphData",
      getSTGraphOCFRawData: "getSTGraphOCFRawData",
      getSTGraphHourlyFGData: "getSTGraphHourlyFGData",
      getStIdStDetailsOfSelectedPondId: "getStIdStDetailsOfSelectedPondId",
      getStGraphLoading: "getStGraphLoading",
      selectStId: "getSelectedStIdInGraph"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    getPmsCount() {
      if (this.getStIdStDetailsOfSelectedPondId[this.selectStId]) {
        console.log('this.getStIdStDetailsOfSelectedPondId[this.selectStId]', this.getStIdStDetailsOfSelectedPondId[this.selectStId]);
        return this.getStIdStDetailsOfSelectedPondId[this.selectStId]
          .pond_mothers.length;
      }
      return 0;
    },
    dataViewOptionsStr() {
      return {
        "Raw Data": "Pond_raw_data",
        "Average Data": "Comn_avrg_data"
      };
    },
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: new Date(),
        distanceFromRefDate: [{ action: "subtract", params: [{ days: 3 }] }],
        timeZone: this.getUserTimeZoneString,
        actionsOnDateRangeItem: []
      });
    },
    pickerOptions() {
      return {
        disabledDate: this.chm__disableDateMethod
      };
    }
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchHourWisePondFeed: "fetchHourWisePondFeed",
      fetchStStatsData: "fetchStStatsData"
    }),
    initComponent() {
      this.chm__initAxisTextKeys("Comn_date_and_time", "Comn_feed_g");
      this.chm__initChartLang();
    },
    parseStOCFData(stOCFData, stFgData, dateRange) {
      const data = this.$lodash.cloneDeep(stOCFData);
      const dates = Object.keys(stOCFData).sort((obj1, obj2) => obj1 - obj2);
      const min15interval = 900000;
      const padDateRanges = this.chm__padDateRanges(dates, min15interval);
      console.log(padDateRanges);
      this.STResponseData = padDateRanges.map(x => {
        return {
          x: +x,
          y: data[x],
          info: {
            total_ocf: data[x] * this.getPmsCount,
            pms: this.getPmsCount,
            fgi: +(stFgData[x] || 0).toFixed(2).toString(),
            x
          }
        };
      });
      console.log(this.STResponseData);
    },
    handleValueChange: function(currentSelectedValues) {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      this.initComponent();
    },
    handleChangeInSt: function() {
      this.parseStOCFData(
        this.getSTGraphOCFRawData,
        this.getSTGraphHourlyFGData,
        this.dateRange
      );
      this.initComponent();
      this.initChartSeries(this.STResponseData || []);
    },
    async fetchStConfigData(stId) {
      const params = { ...this.params };
      params.from_date += "T00:00:00.000Z";
      params.to_date += "T23:59:59.999Z";
      await this.fetchStStatsData({ stId, params, graph: "ST_OCF" });
    },
    chm__initChart() {
      let stResponseData = [];
      if ((this.getStOCFGraphData || []).length === 0) {
        this.chartOptions.series = [];
        return;
      }
      this.parseStOCFData(
        this.getSTGraphOCFRawData,
        this.getSTGraphHourlyFGData,
        this.dateRange
      );
      stResponseData = this.STResponseData || [];
      this.initChartSeries(stResponseData);
    },
    initChartSeries(hourlyIntakeResponse) {
      this.chartOptions.series = [];
      this.chartOptions.tooltip.xDateFormat = `${
        this.dateFormatObject[this.upm__getFormatDateString]
      } ${this.timeFormatObject[this.upm__getFormatTimeString]}`;
      if (hourlyIntakeResponse.length > 0) {
        this.chartOptions.series.push({
          name: this.$t("Comn_ocf_name"),
          type: "column",
          data: hourlyIntakeResponse,
          pointWidth: 5,
          color: "#758e32",
          visible: true,
          dataGrouping: {
            enabled: false
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
// .st-ocf-and-fg-graph {
// }
</style>
