<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondHourlyGraph.vue
Description:This file is the chart component that displays the feed dispensed for each hour in the ponds page. Here x axis represents time when feed is dispensed in the pond and y axis represents amount of feed dispensed in kilograms. Mode of pondmother is indicated using the color.
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="pond-hourly-graph"
    class="pond-hourly-graph"
  >
    <layout-toolbar slot="header" gap="5">
      <p class="card-title">{{ $t("Comn_hourly_feed") }}</p>
      <div class="filler"></div>
      <er-select :value="dataViewOptions" size="mini" @change="handleCommand($event, 'dropdown')">
        <el-option :label="$t('Pond_raw_data')" value="RAW_DATA"></el-option>
        <el-option :label="$t('Comn_avrg_data')" value="AVG_DATA"></el-option>
      </er-select>
      <er-date-picker
        v-model="dateRange"
        type="daterange"
        unlink-panels
        :timeZoneString="getUserTimeZoneString"
        :disableDateMethod="chm__disableDateMethod"
        :availableInterval="chm__availableInterval"
        @change="handleValueChange($event, 'dateChange')"
        :format="upm__getFormatDateString"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import { pondHourlyGraphConfig } from "./chartOptions";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      feed_hourly_logs: [],
      dataViewOptions: "RAW_DATA",
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: { ...pondHourlyGraphConfig },
      loading: false,
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      },
      timeFormatObject: {
        "HH:mm": "%H:%M",
        "hh:mm a": "%l:%M %p"
      }
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondHourlyFeedGraph: "getPondHourlyFeedGraph"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),

    dataViewOptionsStr() {
      return {
        RAW_DATA: "Pond_raw_data",
        AVG_DATA: "Comn_avrg_data"
      };
    },
    chm__defaultDateObjDtRangeForCurrCulture() {
      const daysAvailable = this.getCurrentCulture.doc;
      return daysAvailable > 2
        ? this.dhm__dateUtilsLib.getDateRangeFromRefDate({
            referenceDate: this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
              this.getUserTimeZoneString
            ),
            distanceFromRefDate: [
              { action: "subtract", params: [{ days: 2 }] }
            ],
            timeZone: this.getUserTimeZoneString,
            actionsOnReferenceDate: [],
            actionsOnDateRangeItem: []
          })
        : this.chm__getCultureDateRange;
    }
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("pond-hourly-graph");
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchHourWisePondFeed: "fetchHourWisePondFeed"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent(pondId) {
      this.loading = true;
      const yAxis = "Comn_feed_kg";
      try {
        await this.fetchHourWisePondFeed({
          deviceType: "pond",
          params: this.params
        });
        this.feed_hourly_logs = this.getPondHourlyFeedGraph || [];
        this.handleCommand(this.dataViewOptions);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
        this.chm__initAxisTextKeys("Comn_date", yAxis);
        this.chm__initChartLang();
      }
    },
    pondHourlyFeed(pondFeedDaysWise = [], dateRange) {
      const milliSecs = (date) => date.getTime();
      const dayFeedSchedule = {};
      const dayFeedAutomatic = {};
      const dayFeedingLevel = {};
      pondFeedDaysWise.forEach((dayFeed) => {
        const dateISOStr = dayFeed.date;
        dayFeed.time_slots.forEach((timeSlotFeed) => {
          const correctDate = this.dhm__dateUtilsLib.parseISO(dateISOStr);
          const dateTimeFeed = milliSecs(
            this.dhm__dateUtilsLib.add(correctDate, {
              // seconds: timeSlotFeed.s_time_in_seconds
              hours: Number(timeSlotFeed.s_time.split(":")[0])
            })
          );
            if (dayFeedAutomatic[dateTimeFeed] === undefined) {
              dayFeedAutomatic[dateTimeFeed] = 0;
            }
            if (dayFeedSchedule[dateTimeFeed] === undefined) {
              dayFeedSchedule[dateTimeFeed] = 0;
            }
            if (dayFeedingLevel[dateTimeFeed] === undefined) {
              dayFeedingLevel[dateTimeFeed] = '';
            }
            dayFeedAutomatic[dateTimeFeed] += timeSlotFeed.AUTOMATIC_FEED;
            dayFeedSchedule[dateTimeFeed] += (timeSlotFeed.BASIC_FEED + timeSlotFeed.SCHEDULE_FEED + timeSlotFeed.NONE_FEED);
            dayFeedingLevel[dateTimeFeed] = timeSlotFeed.FEEDING_LEVEL;
          // timeSlotFeed.pond_mothers.reduce(
          //   (acc, pm) => {
          //     if (acc.dayFeedAutomatic[dateTimeFeed] === undefined) {
          //       acc.dayFeedAutomatic[dateTimeFeed] = 0;
          //     }
          //     if (acc.dayFeedSchedule[dateTimeFeed] === undefined) {
          //       acc.dayFeedSchedule[dateTimeFeed] = 0;
          //     }
          //     if (pm.mode === "AUTOMATIC") {
          //       acc.dayFeedAutomatic[dateTimeFeed] += pm.dispensed_feed;
          //     } else {
          //       acc.dayFeedSchedule[dateTimeFeed] += pm.dispensed_feed;
          //     }
          //     return acc;
          //   },
          //   { dayFeedSchedule, dayFeedAutomatic }
          // );
        });
      });
      let arrDayFeedSchedule = Object.keys(dayFeedSchedule).map((key) => {
        return {
          x: Number(key),
          y: dayFeedSchedule[key]
        };
      });
      let arrDayFeedAutomatic = Object.keys(dayFeedAutomatic).map((key) => {
        return {
          x: Number(key),
          y: dayFeedAutomatic[key],
          Z: dayFeedingLevel[key]
        };
      });
      arrDayFeedSchedule = arrDayFeedSchedule.sort((a, b) => a.x - b.x);
      arrDayFeedAutomatic = arrDayFeedAutomatic.sort((a, b) => a.x - b.x);
      return {
        feedSchedule: arrDayFeedSchedule,
        feedAutomatic: arrDayFeedAutomatic
      };
    },
    pondHourlyAvgData: function(pondFeedDaysWise = [], dateRange) {
      const groupByFeedSchedule = {};
      const groupByFeedAutomatic = {};
      const groupDayFeedingLevel = {};
      const milliSecs = (date) => date.getTime();
      const getDate = (dateStr) => this.dhm__dateUtilsLib.parseISO(dateStr);
      const dateRangeDiff =
        this.dhm__dateUtilsLib.differenceInDays(
          getDate(dateRange[1]),
          getDate(dateRange[0])
        ) + 1 || 1;
      // const isoString = dateRange[0] + "T00:00:00.000Z";
      pondFeedDaysWise.forEach((dayFeed) => {
        // const date = milliSecs(new Date(isoString));
        dayFeed.time_slots.forEach((timeSlotFeed) => {
          const dateTimeFeed = milliSecs(
            this.dhm__dateUtilsLib.add(this.dhm__dateUtilsLib.parseISO(dayFeed.date), {
              // seconds: timeSlotFeed.s_time_in_seconds
              hours: Number(timeSlotFeed.s_time.split(":")[0])
            })
          );
          // const dateTimeFeed = date + timeSlotFeed.s_time_in_seconds * 1000;
          if (groupByFeedAutomatic[dateTimeFeed] === undefined) {
            groupByFeedAutomatic[dateTimeFeed] = 0;
          }
          if (groupByFeedSchedule[dateTimeFeed] === undefined) {
            groupByFeedSchedule[dateTimeFeed] = 0;
          }
          if (groupDayFeedingLevel[dateTimeFeed] === undefined) {
            groupDayFeedingLevel[dateTimeFeed] = '';
          }
          groupByFeedAutomatic[dateTimeFeed] += timeSlotFeed.AUTOMATIC_FEED;
          groupByFeedSchedule[dateTimeFeed] += (timeSlotFeed.BASIC_FEED + timeSlotFeed.SCHEDULE_FEED + timeSlotFeed.NONE_FEED);
          groupDayFeedingLevel[dateTimeFeed] = timeSlotFeed.FEEDING_LEVEL;
          // timeSlotFeed.pond_mothers.forEach((pm) => {
          //   if (pm.mode === "AUTOMATIC") {
          //     groupByFeedAutomatic[dateTimeFeed] += pm.dispensed_feed;
          //   } else {
          //     groupByFeedSchedule[dateTimeFeed] += pm.dispensed_feed;
          //   }
          // });
        });
      });
      Object.keys(groupByFeedSchedule).forEach((key) => {
        groupByFeedSchedule[key] = groupByFeedSchedule[key] / dateRangeDiff;
      });
      Object.keys(groupByFeedAutomatic).forEach((key) => {
        groupByFeedAutomatic[key] = groupByFeedAutomatic[key] / dateRangeDiff;
      });
      let resultGroupByFeedSchedule = Object.keys(groupByFeedSchedule).map(
        (key) => {
          return { x: Number(key), y: groupByFeedSchedule[key] };
        }
      );
      let resultGroupByFeedAutomatic = Object.keys(groupByFeedAutomatic).map(
        (key) => {
          return { x: Number(key), y: groupByFeedAutomatic[key], Z: groupDayFeedingLevel[key] };
        }
      );
      resultGroupByFeedSchedule = resultGroupByFeedSchedule.sort(
        (a, b) => a.x - b.x
      );
      resultGroupByFeedAutomatic = resultGroupByFeedAutomatic.sort(
        (a, b) => a.x - b.x
      );
      return {
        groupByFeedSchedule: resultGroupByFeedSchedule,
        groupByFeedAutomatic: resultGroupByFeedAutomatic
      };
    },
    handleValueChange: async function(currentSelectedValues, type = '') {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(this.chm__pondId);
      if (type === 'dateChange') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Hourly Feed Graph - Date Filter" });
      }
    },
    chm__initChart() {
      let result = {};
      let schedulePMsFeed = [];
      let automaticPMsFeed = [];
      let totalSchedulePMsFeed = 0;
      let totalAutomaticPMsFeed = 0;
      if (this.feed_hourly_logs.length === 0) {
        // let x = this.generateData();
        // let x = { schedulePMsFeed: [], automaticPMsFeed: [] };
        // schedulePMsFeed = x.schedulePMsFeed;
        // automaticPMsFeed = x.automaticPMsFeed;
        this.chartOptions.series = [];
        return;
      } else {
        if (this.dataViewOptions === "AVG_DATA") {
          result = this.pondHourlyAvgData(
            this.feed_hourly_logs || [],
            this.dateRange
          );
          schedulePMsFeed = result.groupByFeedSchedule;
          automaticPMsFeed = result.groupByFeedAutomatic;
        } else if (this.dataViewOptions === "RAW_DATA") {
          result = this.pondHourlyFeed(
            this.feed_hourly_logs || [],
            this.dateRange
          );
          schedulePMsFeed = result.feedSchedule;
          automaticPMsFeed = result.feedAutomatic;
        }
        totalSchedulePMsFeed = (
          schedulePMsFeed.map((feed) => feed.y) || []
        ).reduce((acc, curr) => acc + curr, 0);
        totalAutomaticPMsFeed = (
          automaticPMsFeed.map((feed) => feed.y) || []
        ).reduce((acc, curr) => acc + curr, 0);
      }
      this.initChartSeries(
        automaticPMsFeed,
        schedulePMsFeed,
        totalSchedulePMsFeed,
        totalAutomaticPMsFeed
      );
    },
    initChartSeries(
      automaticPMsFeed,
      schedulePMsFeed,
      totalSchedulePMsFeed,
      totalAutomaticPMsFeed
    ) {
      this.chartOptions.series = [];
      if (automaticPMsFeed.length === 0 && schedulePMsFeed.length === 0) {
        return;
      }
      this.chartOptions.tooltip.xDateFormat = `${
        this.dateFormatObject[this.upm__getFormatDateString]
      } ${this.timeFormatObject[this.upm__getFormatTimeString]}`;
      this.chartOptions.series.push({
        // name: this.$t("PM_automatic"),
        name: {
          legend: this.$t("PM_automatic"),
          tooltip: this.$t("PM_automatic"),
          tooltipName: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +
            '<td style="text-align: right"><b>{point.Z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
        },
        data: automaticPMsFeed,
        color: "#758e32",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
      this.chartOptions.series.push({
        // name: this.$t("Comn_other"),
        name: {
          legend: this.$t("Comn_other"),
          tooltip: this.$t("Comn_other"),
          // tooltipName: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            // '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +<td style="text-align: right"><b>{point.Z}</b></td>
            '</tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
        },
        data: schedulePMsFeed,
        color: "#f1b727",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
      this.chartOptions.series.push({
        // name: this.$t("Comn_feed_kg"),
        name: {
          legend: this.$t("Comn_feed_kg"),
          tooltip: this.$t("Comn_feed_kg")
        },
        type: "pie",
        data: [
          {
            name: this.$t("Comn_other"),
            y: Number(totalSchedulePMsFeed.toFixed(2)),
            color: "#f1b727"
          },
          {
            name: this.$t("PM_automatic"),
            y: Number(totalAutomaticPMsFeed.toFixed(2)),
            color: "#758e32"
          }
        ],
        center: ["90%", "5%"],
        size: 70,
        showInLegend: false,
        dataLabels: {
          enabled: false
        }
      });
    },
    handleCommand(command, type = '') {
      this.dataViewOptions = command;
      this.chm__initChart();
      if (type === 'dropdown') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Hourly Feed Graph - Raw - Average - Dropdown" });
      }
    }
  }
};
</script>

<style lang="scss">
.pond-hourly-graph {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
  .er-select {
    flex-basis: 20%;
    .el-input__inner {
      @include responsiveProperty(height, 24px, 27px, 30px);
    }
  }
}
</style>
