<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmScheduleGraph.vue
Description: This file is the chart component that displays the feed dispensed at pondmother level. The graph shows multiple pondmothers by differentiating with color. Here x axis is time and y axis represents the feed dispensed value.
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="pm-schedule-graph"
  >
    <layout-toolbar slot="header" gap="5">
      <p class="card-title">{{ $t('PM_individual') }}</p>
      <div class="filler"></div>
      <er-date-picker
        v-model="dateRange"
        type="daterange"
        unlink-panels
        :format="formatDate"
        :timeZoneString="getUserTimeZoneString"
        :disableDateMethod="chm__disableDateMethod"
        :availableInterval="chm__availableInterval"
        @change="handleValueChange($event, 'dateChange')"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <!-- highcharts tag -->
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandlerMixin from '@/mixins/errorHandlerMixin';
import datesHandlerMixin from '@/mixins/datesHandlerMixin';
import chartMixin from '@/mixins/chartMixin';
import { pmScheduleGraph } from './chartOptions';
import PmSchedule from '@/model/pmSchedule';
import userPreferenceMixin from '@/mixins/userPreferenceMixin';
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      dateRange: [],
      feed_schedule_logs: [],
      params: {
        from_date: '',
        to_date: '',
        month_of: null,
        week_of: null
      },
      chartOptions: pmScheduleGraph,
      loading: false,
      dateFormatObject: {
        'dd/MM/yyyy': '%d/%m/%Y',
        'dd MMM': '%d %b',
        'dd MMM, yy': '%d %b, %y'
      },
      currActiveSeries: []
    };
  },
  computed: {
    ...mapGetters('user', {
      getPreferredUnits: 'getPreferredUnits'
    }),
    ...mapGetters('pondDetails', {
      getPmSlotWiseFeedGraph: 'getPmSlotWiseFeedGraph',
      getPmIdPmDetailsOfSelectedPondId: 'getPmIdPmDetailsOfSelectedPondId',
      getArrPmDetailsOfSelectedPondId: 'getArrPmDetailsOfSelectedPondId'
    }),
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.chm__getDateObjArrOfCultureDateRange;
    },
    pondMothersWithDetails() {
      return this.getArrPmDetailsOfSelectedPondId || [];
    },
    pondMothers() {
      const data = [];
      this.pondMothersWithDetails.forEach((ele) => {
        data.push({ code: ele.code, id: ele._id });
      });
      return data;
    },
    pmIdToPmTitles() {
      return this.pondMothersWithDetails.reduce((acc, curr) => {
        acc[curr._id] = curr.title || curr.code;
        return acc;
      }, {});
    },
    formatDate() {
      return this.upm__getFormatDateString;
    }
  },
  mounted() {
    this.chm__registerResizeObserver('pm-schedule-graph');
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver('pm-schedule-graph');
  },
  methods: {
    ...mapActions('pondDetails', {
      fetchSlotWiseFeed: 'fetchSlotWiseFeed',
      fetchDeviceDetailsDetailsByDate: 'fetchDeviceDetailsDetailsByDate'
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent(fetchPmsFromLog) {
      this.loading = true;
      // const feedLimit = {};
      // const dispensedLimit = {};
      try {
        if (fetchPmsFromLog) {
          console.log('this.params', this.params);
          await this.fetchDeviceDetailsDetailsByDate({
            pondId: this.pondId,
            device_type: 'POND_MOTHER',
            from_date: this.dhm__dateUtilsLib.isoDayStartFormatString.replace(
              /<short-iso-date>/,
              this.params.from_date
            ),
            to_date: this.dhm__dateUtilsLib.isoDayEndFormatString.replace(
              /<short-iso-date>/,
              this.params.to_date
            )
          });
        }
        await this.fetchSlotWiseFeed({
          deviceType: 'pond_mother',
          params: this.params
        });
        this.feed_schedule_logs = this.getPmSlotWiseFeedGraph;
        this.currActiveSeries = [];
        this.chm__initAxisTextKeys(
          'Comn_date_and_time',
          'PM_dispensed_feed_kg'
        );
        this.chm__initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    getPMScheduleFeedData(pondFeedDaysWise) {
      const milliSecs = (date) => date.getTime();
      const pmsDayWiseSchedules = pondFeedDaysWise
        .map((schedule) => {
          const pmSchedule = PmSchedule.transformTSPMHierachyToPMTSHierachy(
            schedule,
            this.pmIdToPmTitles
          );
          return pmSchedule.map((eachPmSchedule) => {
            eachPmSchedule.date_secs = milliSecs(
              // this.dhm__dateUtilsLib.parse(
              //   schedule.date,
              //   this.dhm__dateUtilsLib.isoFormatString,
              //   new Date()
              // )
              this.dhm__dateUtilsLib.parseISO(schedule.date)
            );
            eachPmSchedule.setFormattedScheduleDate = {
              dayInSecs: milliSecs(
                this.dhm__dateUtilsLib.parse(
                  new Date(eachPmSchedule.date_secs).toISOString(),
                  this.dhm__dateUtilsLib.isoFormatString,
                  new Date()
                )
              ),
              userTimeZoneString: this.getUserTimeZoneString,
              format: this.formatDate
            };
            return eachPmSchedule;
          });
        })
        .reduce((pmCodeToPmSchedules, eachDayPmsSchedule) => {
          return eachDayPmsSchedule.reduce((acc, eachPmSchedule) => {
            if (!acc[eachPmSchedule.code]) {
              acc[eachPmSchedule.code] = [];
            }
            acc[eachPmSchedule.code].push(eachPmSchedule);
            return acc;
          }, pmCodeToPmSchedules);
        }, {});
      Object.keys(pmsDayWiseSchedules).forEach((pmCode) => {
        pmsDayWiseSchedules[pmCode] = pmsDayWiseSchedules[pmCode].sort(
          (a, b) => a.date_secs - b.date_secs
        );
      });
      return this.generateSeriesForPmSchedules(pmsDayWiseSchedules);
    },
    generateSeriesForPmSchedules(pmSchedules) {
      const milliSecs = (date) => date.getTime();
      const arrColors = this.getNColors(Object.keys(pmSchedules).length);
      const componentContext = this;
      let series = Object.keys(pmSchedules).map((pondMotherCode, index) => {
        const pmIdentity = {
          device_title:
            this.pmIdToPmTitles[
              pmSchedules[pondMotherCode][0].pond_mother_id
            ] || pondMotherCode,
          device_code: pondMotherCode
        };
        const [pond_mother_id, managed_by] = [
          'pond_mother_id',
          'managed_by'
        ].map((key) => {
          return pmSchedules[pondMotherCode][0][key];
        });
        const pmPreference = pmIdentity[this.upm__getPrefDeviceIdentityKey];

        // new code starts
        // let dayStart = this.dhm__dateUtilsLib.parseISO(
        //   this.dateRange[0] + "T00:00:00.000Z"
        // );
        // const dayEnd = this.dhm__dateUtilsLib.parseISO(
        //   this.dateRange[1] + "T00:00:00.000Z"
        // );
        // while (dayStart <= dayEnd) {
        //   dayStart = this.dhm__dateUtilsLib.add(dayStart, { days: 1 });
        // }
        // const datePMSheduleOBj = [{}];
        // let startDate = this.dhm__dateUtilsLib.parseISO(
        //   this.dateRange[0] + "T00:00:00.000Z"
        // );
        // console.log("pmSchedules[pondMotherCode]", pmSchedules[pondMotherCode]);
        // pmSchedules[pondMotherCode].map((eachDaySchedule, i) => {
        //   datePMSheduleOBj[this.dhm__formatTZ(startDate, "yyyy-MM-dd")] = {
        //     x: +eachDaySchedule.date_secs,
        //     y: eachDaySchedule.dispensed_feed,
        //     pm_schedule: eachDaySchedule,
        //     preferred_device_name: pmPreference,
        //     preferred_time_format: this.upm__getFormatTimeString
        //   };
        //   startDate = this.dhm__dateUtilsLib.add(startDate, { days: 1 });
        // });
        // console.log("startDate", startDate);
        // console.log("datePMSheduleOBj", datePMSheduleOBj);
        // console.log("this.dateRange", this.dateRange);

        // new code ends
        const dateWiseSchedules = pmSchedules[pondMotherCode]
          .map((eachDaySchedule) => {
            return {
              x: +eachDaySchedule.date_secs,
              y: eachDaySchedule.dispensed_feed,
              pm_schedule: eachDaySchedule,
              preferred_device_name: pmPreference,
              preferred_time_format: this.upm__getFormatTimeString
            };
          })
          .reduce((acc, curr) => {
            acc[curr.x] = curr;
            return acc;
          }, {});
        const availDateRangeKeys = this.dhm__dateUtilsLib
          .eachDayOfInterval({
            start: this.dhm__dateUtilsLib.parse(
              this.dhm__dateUtilsLib.isoDayStartFormatString.replace(
                /<short-iso-date>/,
                this.dateRange[0]
              ),
              this.dhm__dateUtilsLib.isoFormatString,
              new Date()
            ),
            end: this.dhm__dateUtilsLib.parse(
              this.dhm__dateUtilsLib.isoDayEndFormatString.replace(
                /<short-iso-date>/,
                this.dateRange[1]
              ),
              this.dhm__dateUtilsLib.isoFormatString,
              new Date()
            )
          })
          .map((x) => {
            return new Date(
              this.dhm__dateUtilsLib.formatDate(
                x,
                this.dhm__dateUtilsLib.isoFormatString
              )
            ).valueOf();
          });
        // pmSchedules[pondMotherCode].map((eachDaySchedule) => {
        //   return {
        //     x: +eachDaySchedule.date_secs,
        //     y: eachDaySchedule.dispensed_feed,
        //     pm_schedule: eachDaySchedule,
        //     preferred_device_name: pmPreference,
        //     preferred_time_format: this.upm__getFormatTimeString
        //   };
        // }),

        const defaultObj = (dateTimeEpoch) => {
          const pmSchedule = new PmSchedule(pond_mother_id, managed_by);
          // console.log('this.dhm__dateUtilsLib.getUnixTime(new Date(dateTimeEpoch))', this.dhm__dateUtilsLib.getUnixTime(new Date(dateTimeEpoch)));
          pmSchedule.setFormattedScheduleDate = {
            dayInSecs: milliSecs(
              this.dhm__dateUtilsLib.parse(
                new Date(dateTimeEpoch).toISOString(),
                this.dhm__dateUtilsLib.isoFormatString,
                new Date()
              )
            ),
            userTimeZoneString: this.getUserTimeZoneString,
            format: this.upm__getFormatDateString
          };
          return {
            x: dateTimeEpoch,
            y: 0,
            pm_schedule: pmSchedule,
            preferred_device_name: pmPreference,
            preferred_time_format: this.upm__getFormatTimeString
          };
        };
        return {
          id: pmSchedules[pondMotherCode][0].pond_mother_id,
          name: pmPreference,
          data: availDateRangeKeys.map((x) => {
            if (dateWiseSchedules[x]) {
              return dateWiseSchedules[x];
            }
            return defaultObj(x);
          }),
          color: arrColors[index],
          events: {
            legendItemClick: function() {
              const pmIdIndex = componentContext.currActiveSeries.indexOf(
                this.userOptions.id
              );
              console.log(componentContext.currActiveSeries);
              if (pmIdIndex > -1) {
                componentContext.currActiveSeries.splice(pmIdIndex, 1);
              } else {
                componentContext.currActiveSeries.push(this.userOptions.id);
              }
              if (componentContext.currActiveSeries.length > 4) {
                const removedItem = componentContext.currActiveSeries.shift();
                this.chart.get(removedItem).hide();
              }
              componentContext.$nextTick(() => {
                const chartXAxis = this.chart.xAxis[0];
                const xAxis = this.xAxis;
                chartXAxis.setExtremes(xAxis.dataMin, xAxis.dataMax);
              });
            }
          },
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, arrColors[index] + 'ff'],
              [1, arrColors[index] + '33']
            ]
          },
          visible: false
        };
      });
      if (series.length > 0) {
        series.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.name, b.name)
        );
        series[0].visible = true;
        this.currActiveSeries = [];
        this.currActiveSeries.push(series[0].id);
      } else {
        series = [];
      }
      console.log('series', series);
      return series;
    },
    async handleValueChange(currentSelectedValues, type = '') {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(true);
      if (type === 'dateChange') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - PondMother Individual Graph - Date Filter" });
      }
    },
    chm__initChart() {
      const series = this.getPMScheduleFeedData(this.feed_schedule_logs);
      this.chartOptions.series = series;
      this.$nextTick(() => {
        if (!this.$refs.highcharts) return;
        const chart = this.$refs.highcharts.chart;
        const xAxis = chart.xAxis[0];
        xAxis.setExtremes(xAxis.dataMin, xAxis.dataMax);
      });
    },
    getNColors(numOfColors) {
      const arrColors = [];
      const initL = (100 - 20) / numOfColors;
      const initH = (360 - 60) / numOfColors;
      for (let i = 1; i <= numOfColors; i++) {
        arrColors.push(this.hsl2rgb(initH * i, 96 * 0.01, initL * i * 0.01));
      }
      return arrColors;
    },
    hsl2rgb(h, s, l) {
      const a = s * Math.min(l, 1 - l);
      const f = (n, k = (n + h / 30) % 12) =>
        l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return this.rgbToHex(f(0), f(8), f(4));
    },
    rgbToHex(r, g, b) {
      return (
        '#' +
        [r, g, b]
          .map((x) =>
            Math.round(x * 255)
              .toString(16)
              .padStart(2, 0)
          )
          .join('')
      );
    }
  }
};
</script>
<style lang="scss">
.pm-sch-graph-toottip-table {
  display: inline-block;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  vertical-align: top;
  td,
  th {
    text-align: center;
    border: 1px solid #ddd;
  }
}
</style>
