/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for charts in the ponds page
*/
import Highcharts from "highcharts";
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { cloneDeep } from "lodash";
import {
  baseChartConfig as baseGraphConfig,
  xAxisConfig
} from "@/config/baseChartConfig";
import dateUtils from "@/utils/dateUtils";
import { generateColorForModeInGraph } from "@/utils/componentColors";
import i18n from "@/config/i18n";
import store from "@/store/index";
const dateFormatObject = {
  "dd/MM/yyyy": "%d/%m/%Y",
  "dd MMM": "%d %b",
  "dd MMM, yy": "%H,%d %b, %y"
};
const dateandTimeFormatObject = {
  "dd/MM/yyyy": "%H:%M, %d/%m/%Y",
  "dd MMM": "%H:%M, %d %b",
  "dd MMM, yy": "%H:%M, %d %b, %y"
};

const legend = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled
  };
};
const exporting = function(enabled) {
  return {
    enabled: enabled
  };
};
const title = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled,
    text: undefined
  };
};
const tooltip = {
  default: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  numericXAxisToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small><strong>DOC:{point.x}</strong></small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
  },
  pondScheduleToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name.tooltip}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    // xDateFormat: CHART_DATE_FORMATS.dmy
    xDateFormat:
      dateFormatObject[store.getters["user/getPreferredUnits"].date_format]
  },
  hourlyToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name.tooltip}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
    // xDateFormat: CHART_DATE_FORMATS.dmyt
  },
  manualFeedToolTip: {
    ...baseGraphConfig.tooltip,
    xDateFormat:
      dateFormatObject[store.getters["user/getPreferredUnits"].date_format]
  },
  stOCFToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small>",
    pointFormat:
      '<br><table><span style="color:#255379">OCF(g)</span>: <b>{point.y}</b><br/><span style="color:#255379">FGI (min)</span>: <b>{point.info.fgi}</b><br/>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  },
  pgStatusToolTip: {
    shared: true,
    useHTML: true,
    formatter: function() {
      const time = new Highcharts.Time();
      const series = this.points[0].series;
      const point = this.points[0].point;
      return `<small>${time.dateFormat(point.timeFormat, this.x)}</small>
      <table>
      ${this.points.reduce((acc, point1) => {
        return (
          acc +
          `<tr>
        <td style="color: ${point1.series.color}">
        ${point1.series.name.series_name}: 
        </td>
        <td style="text-align: right">
          <b>${point1.y}</b>
        </td>
      </tr>`
        );
      }, "")}
      <tr>
        <td style='color:#000000'>
          <b>
            ${series.name.device_code_text}:
          </b>
        </td>
        <td style='text-align: right'>  
        <b>
          ${series.name.device_code}</b>
        </td>
      </tr>
      </table>`;
    },
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  },
  pmScheduleToolTip: {
    split: false,
    className: "pm-schedule-graph--tooltip",
    shared: true,
    useHTML: true,
    formatter: function() {
      // const series = this.points[0].series;
      const tooltipGenerator = function(point) {
        const pmSchedule = point.pm_schedule;
        const timeFormat = point.preferred_time_format;
        return `
      <table class="pm-sch-graph-toottip-table">
       <thead>
              <tr>
                <th scope="col" colspan="6" style="text-align:center;">
                 ${pmSchedule.formatted_date}, ${point.preferred_device_name}
                </th>
              </tr>
              <tr>
                <th scope="col" colspan="6" style="text-align:center;">
                ${i18n.t("PM_tf")}:${Number(pmSchedule.feed).toFixed(
          1
        )},${i18n.t("Comn_df")}:${Number(pmSchedule.dispensed_feed).toFixed(1)}
                </th>
              </tr>
              <tr>
              <th>${i18n.t("PM_sch")}</th>
              <th>${i18n.t("mode")}</th>
              <th>${i18n.t("Comn_from")}</th>
              <th>${i18n.t("Comn_to")}</th>
              <th>${i18n.t("PM_tf")}</th>
              <th>${i18n.t("Comn_df")}</th>
              </tr>
            </thead>
            ${pmSchedule.time_slots
              .map(
                (
                  { s_time, e_time, dispensed_feed, feed, managed_by },
                  index
                ) => {
                  const mode = generateColorForModeInGraph(managed_by);
                  const sTimeObj = dateUtils.set(dateUtils.getCurrDate(), {
                    hours: s_time.split(":")[0],
                    minutes: s_time.split(":")[1]
                  });
                  const eTimeObj = dateUtils.set(dateUtils.getCurrDate(), {
                    hours: e_time.split(":")[0],
                    minutes: e_time.split(":")[1]
                  });

                  return `<tr>
           <th scope="row" style="color:#000000">${i18n.t("s")}${index + 1}</th>
            <td title="${mode.translation}" style="color:${
                    mode.color
                  };">&#11044;</td>
            <td>${dateUtils.formatDate(sTimeObj, timeFormat)}</td>
            <td>${dateUtils.formatDate(eTimeObj, timeFormat)}</td>
            <td>${Number(feed).toFixed(1)}</td>
            <td>${Number(dispensed_feed).toFixed(1)}</td>
            </tr>`;
                }
              )
              .join("")}
      </table>
      `;
      };
      return this.points
        .map(eachPoint => {
          return tooltipGenerator(eachPoint.point);
        })
        .join("");
    }
  },
  labtestToolTip: {
    shared: true,
    useHTML: true,
    formatter: function() {
      const time = new Highcharts.Time();
      return `<small>${time.dateFormat(CHART_DATE_FORMATS.dmyt, this.x)}</small>
      <table>
      ${this.points.reduce((acc, point1) => {
        return (
          acc +
          `<tr>
        <td style="color: ${point1.series.color}">
        ${point1.series.name.series_name}: 
        </td>
        <td style="text-align: right">
          <b>${point1.y}</b>
        </td>
      </tr>`
        );
      }, "")}
      </table>`;
    },
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  }
};
const legendObj = {
  default: {
    legend: true
  },
  pondScheduleLegend: {
    enabled: true,
    useHTML: true,
    labelFormatter: function() {
      return this.name.legend;
    }
  },
  pondHourlyLegend: {
    enabled: true,
    useHTML: true,
    labelFormatter: function() {
      return this.name.legend;
    }
  },
  manualFeedGraph: {
    enabled: true,
    useHTML: true,
    labelFormatter: function() {
      return this.name.legend;
    }
  },
  AbwBiomassGraphConfig: {
    enabled: true,
    useHTML: true,
    labelFormatter: function() {
      return this.name.legend;
    }
  },

  pgStatusGraph: {
    enabled: true,
    useHTML: true,
    labelFormatter: function() {
      return this.name.series_name;
    }
  }
};
const labelFormatter = {
  formatter: function() {
    const startOfDay = dateUtils.startOfDay(
      dateUtils.utcToZonedTime(this.value, "UTC")
    );
    const value = dateUtils.utcToZonedTime(this.value, "UTC");
    switch (this.tickPositionInfo.unitName) {
      case "hour":
        if (dateUtils.isEqual(value, startOfDay)) {
          return dateUtils.formatTZ(
            dateUtils.utcToZonedTime(this.value, "UTC"),
            "d. MMM",
            {
              timeZone: "UTC",
              locale: dateUtils.localesMap[i18n.locale]
            }
          );
        }
    }
    return this.axis.defaultLabelFormatter.call(this);
  }
};
const pondHourlyGraphConfig = {
  ...baseGraphConfig,
  tooltip: {
    ...tooltip.hourlyToolTip,
    xDateFormat:
      dateandTimeFormatObject[
        store.getters["user/getPreferredUnits"].date_format
      ]
  },
  xAxis: {
    events: {
      afterSetExtremes: () => {}
    },
    labels: labelFormatter,
    ordinal: false,
    type: "datetime",
    showEmpty: false,
    title: {
      text: "",
      align: "middle",
      style: {
        color: "#000000"
      }
    }
  },
  legend: legendObj.pondHourlyLegend, // legend(true),
  exporting: exporting(false),
  title: title(false)
};
const chartMeasurements = {
  marginLeft: 100,
  marginRight: 100
};
const stOCFGraphConfig = {
  ...cloneDeep(baseGraphConfig),
  chart: {
    ...baseGraphConfig.chart,
    ...chartMeasurements
  },
  tooltip: { ...tooltip.stOCFToolTip },
  legend: legend(true),
  exporting: exporting(false),
  title: title(false),
  xAxis: {
    events: {
      afterSetExtremes: () => {}
    },
    labels: labelFormatter,
    type: "datetime",
    title: {
      align: "middle"
    },
    startOnTick: true,
    showEmpty: false,
    ordinal: false,
    minRange: 60 * 1000
  }
};
const stGraphConfig = {
  ...cloneDeep(baseGraphConfig),
  chart: {
    ...baseGraphConfig.chart,
    ...chartMeasurements
  },
  tooltip: { ...tooltip.hourlyToolTip },
  legend: legendObj.pondHourlyLegend, // legend(true),
  exporting: exporting(false),
  title: title(false),
  xAxis: {
    events: {
      afterSetExtremes: () => {}
    },
    type: "datetime",
    title: {
      align: "middle"
    },
    labels: labelFormatter,
    startOnTick: true,
    ordinal: false,
    minRange: 3600 * 1000,
    minPadding: 0,
    showEmpty: false
  },
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      title: {
        text: "",
        style: {
          color: "#000000"
        }
      },
      opposite: false
    },
    {
      // Tertiary yAxis
      title: {
        text: "",
        style: {
          color: "#000000"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      opposite: true
    }
  ]
};
const pondScheduleGraphConfig = {
  ...baseGraphConfig,
  tooltip: { ...tooltip.pondScheduleToolTip },
  xAxis: xAxisConfig({
    type: "datetime",
    minRange: 3600 * 1000
  }),
  legend: legendObj.pondScheduleLegend,
  exporting: exporting(false),
  title: title(false)
};
const pondScheduleFeedAreaGraphConfig = {
  ...baseGraphConfig,
  tooltip: { ...tooltip.pondScheduleFeedAreaToolTip },
  xAxis: xAxisConfig({
    type: "datetime",
    minRange: 3600 * 1000
  }),
  // legend: legendObj.pondScheduleLegend,
  exporting: exporting(false),
  title: title(false)
};

const pgStatusGraphConfig = {
  ...baseGraphConfig,
  tooltip: { ...tooltip.pgStatusToolTip },
  legend: legendObj.pgStatusGraph,
  exporting: exporting(false),
  title: title(false),
  plotOptions: {
    series: {
      turboThreshold: 10000000,
      connectNulls: true,
      dataGrouping: { enabled: false }
    }
  },
  xAxis: {
    showEmpty: false,
    ordinal: false,
    labels: {
      formatter: labelFormatter.formatter,
      style: {
        color: "#000000"
      }
    },
    title: {
      align: "middle"
    },
    minRange: 3600 * 1000, // one hour
    type: "datetime"
  },
  yAxis: [
    {
      // DO
      showEmpty: false,
      gridLineWidth: 0,
      tickAmount: 3,
      title: {
        style: {
          color: "#000000"
        }
      },
      labels: {
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      opposite: false
    },
    {
      // pH
      showEmpty: false,
      gridLineWidth: 0,
      visible: false,
      title: {
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      labels: {
        style: {
          color: "#000000"
        }
      },
      opposite: false
    },
    {
      // temp
      showEmpty: false,
      tickAmount: 4,
      labelMaxWidth: 50,
      labels: {
        style: {
          color: "#000000"
        }
      },
      title: {
        style: {
          color: "#000000"
        }
      },
      opposite: true
    },
    {
      // salinity
      showEmpty: false,
      tickAmount: 4,
      labelMaxWidth: 50,
      labels: {
        style: {
          color: "#000000"
        }
      },
      title: {
        style: {
          color: "#000000"
        }
      },
      opposite: true
    }
  ]
};

const pmScheduleGraph = {
  ...baseGraphConfig,
  legend: legend(true),
  exporting: exporting(false),
  title: title(false),
  tooltip: { ...tooltip.pmScheduleToolTip },
  xAxis: xAxisConfig({
    type: "datetime",
    minRange: 24 * 3600 * 1000
  })
};

const manualFeedGraph = {
  ...baseGraphConfig,
  legend: legend(true),
  exporting: exporting(false),
  title: title(false),
  tooltip: tooltip.manualFeedToolTip,
  xAxis: xAxisConfig({
    type: "datetime",
    minRange: 24 * 3600 * 1000
  })
};
const labTestsGraph = {
  ...baseGraphConfig,
  exporting: exporting(false),
  // legend: legendObj.pgStatusGraph,
  title: title(false),
  plotOptions: {
    series: {
      turboThreshold: 10000000,
      connectNulls: true,
      dataGrouping: { enabled: false },
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 2
        }
      }
    }
  },
  tooltip: tooltip.labtestToolTip,
  xAxis: {
    showEmpty: false,
    ordinal: false,
    labels: {
      formatter: labelFormatter.formatter,
      style: {
        color: "#000000"
      }
    },
    title: {
      align: "middle",
      text: "Date Time"
    },
    minRange: 3600 * 1000, // one hour
    type: "datetime"
  },
  yAxis: [
    {
      // DO
      showEmpty: false,
      gridLineWidth: 0,
      tickAmount: 3,
      title: {
        style: {
          color: "#000000"
        }
      },
      labels: {
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      opposite: false
    }
  ]
};

const AbwBiomassGraphConfig = {
  ...baseGraphConfig,
  legend: legend(true),
  exporting: exporting(false),
  title: title(false),
  tooltip: tooltip.numericXAxisToolTip,
  xAxis: [
    {
      gridLineColor: "#eeeeee",
      gridLineWidth: 0.5,
      title: {
        text: "DOC"
      },
      labels: {
        formatter: function() {
          return this.value;
        }
      }
    }
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      title: {
        text: "Weight",
        style: {
          color: "#000000"
        }
      },
      opposite: false
    },
    {
      // Tertiary yAxis
      title: {
        text: "Biomass / survival rate",
        style: {
          color: "#000000"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      opposite: true
    }
  ]
};
const lithiumBatteryGraphConfig = {
  ...baseGraphConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
export {
  stGraphConfig,
  stOCFGraphConfig,
  pondHourlyGraphConfig,
  pondScheduleGraphConfig,
  pondScheduleFeedAreaGraphConfig,
  pgStatusGraphConfig,
  pmScheduleGraph,
  manualFeedGraph,
  AbwBiomassGraphConfig,
  lithiumBatteryGraphConfig,
  labTestsGraph
};
