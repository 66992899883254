/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: formRulesMixin.js
Description: This mixin contains the rules and validation functionality for components containing forms
*/
const formRulesMixin = {
  computed: {
    frm__rules: function() {
      return {
        first_name: [
          {
            required: true,
            message: this.$t("Usrs_first_name_required"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("Usrs_last_name_required"),
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t("Comn_mobile_num_req"),
            trigger: "blur"
          }
        ],
        country: [
          {
            required: true,
            message: this.$t("Comn_country_required"),
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: this.$t("Usrs_email_field_required"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("Usrs_pwd_required"),
            trigger: "blur"
          }
        ],
        confirm_password: [
          {
            required: true,
            message: this.$t("Usrs_pwd_required"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  methods: {
    frm__validateForm(callBack) {
      this.$refs[this.formName].$refs.elForm.validate((valid, errorObj) => {
        if (valid) {
          callBack();
        } else {
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
    frm__validateFormType2(callBack) {
      this.$refs[this.formName].validate((valid, errorObj) => {
        if (valid) {
          callBack();
        } else {
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
    frm__resetForm() {
      this.$refs[this.formName].clearValidate();
    }
  }
};

export default formRulesMixin;
