/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartMixin.js
Description: This mixin is useful for handling the common functionality related to the ponds page charts such as culture dates, init x,y axis, init dates related to the datepicker
*/
import Highcharts from "highcharts";
import { mapGetters } from "vuex";
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/src/utils/resize-event";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
const chartMixin = {
  mixins: [userPreferenceMixin, datesHandlerMixin],
  computed: {
    ...mapGetters("pondDetails", {
      getCurrentCulture: "getCurrentCulture",
      pondId: "getSelectedPondId",
      getSelectedPond: "getSelectedPondWithDeviceDetails"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    chm__pondIdHarvestId() {
      return `${this.getCurrUserLocation._id}_${this.pondId}_${this.chm__getHarvestId}`;
    },
    chm__getHarvestId() {
      return this.getCurrentCulture.harvest_id;
    },
    chm__availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    chm__getCultureDateRange() {
      return [
        this.getCurrentCulture.start_date,
        this.getCurrentCulture.end_date
      ];
    },
    chm__getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.chm__getCultureDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.chm__getCultureDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    },
    chm__dateFormat() {
      return "yyyy-MM-dd";
    },
    chm__getCurrLang() {
      return this.$i18n.locale;
    }
  },
  watch: {
    chm__pondIdHarvestId: {
      handler: function(newHarvestId, oldHarvestId) {
        if (this.chm__getHarvestId === undefined) return;
        if (this.chm__getHarvestId === 0) {
          this.chm__initDateRangeWithMomentObj(
            this.chm__defaultDateObjDtRangeForCurrCulture
          );
        } else {
          this.chm__initDateRangeWithMomentObj(
            this.chm__getDateObjArrOfCultureDateRange
          );
        }
      },
      immediate: true
    },
    chm__pondId: async function() {
      await this.initComponent();
    },
    chm__getCurrLang: function(newvalue, oldvalue) {
      this.chm__initChartLang();
    }
  },
  data: function() {
    return {
      chm__xAxisTextKey: "Comn_feed",
      chm__yAxisTextKey: "Comn_fcr"
    };
  },
  beforeCreate() {
    if (this != null) {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    }
  },
  methods: {
    chm__padDateRanges(arrDateRanges, intervalGap = 3600000) {
      const startOfDay1 = function(value) {
        return +value - (+value % 86400000);
      };
      if (arrDateRanges.length === 0) return [];
      let arrayDates1 = arrDateRanges.map(x => +x);
      const start = +startOfDay1(arrayDates1[0]);
      const endOfDay = start + 86400000 - intervalGap;
      const length1 = arrayDates1.length;
      const lastItem1 = arrayDates1[length1 - 1];
      if (!endOfDay || !lastItem1) return [];
      if (arrayDates1[0] - start > intervalGap) {
        arrayDates1 = [start, ...arrayDates1];
      }
      if (endOfDay - lastItem1 >= intervalGap) {
        arrayDates1 = [...arrayDates1, endOfDay];
      }
      const newArray = [arrayDates1[0]];
      for (let i = 1; i < arrayDates1.length; i++) {
        let k = arrayDates1[i - 1] + intervalGap;
        const limit = k =>
          k <= k + Math.ceil((arrayDates1[i] - k) / intervalGap) &&
          k <= endOfDay;
        for (; limit(k); k += intervalGap) {
          newArray.push(k);
        }
      }
      return [...new Set([...newArray, ...arrayDates1])].sort((a, b) => a - b);
    },
    chm__debounce: function(ms, fn) {
      var timer;
      return function() {
        clearTimeout(timer);
        var args = Array.prototype.slice.call(arguments);
        args.unshift(this);
        timer = setTimeout(fn.bind.apply(fn, args), ms);
      };
    },
    chm__registerResizeObserver(componentRef) {
      this.$nextTick(() => {
        addResizeListener(
          this.$refs[componentRef].$el,
          this.chm__debounce(100, () => {
            requestAnimationFrame(secs => {
              if (this.$refs.highcharts) {
                this.$refs.highcharts.chart.reflow();
              }
            });
          })
        );
      });
    },
    chm__unRegisterResizeObserver(componentRef) {
      removeResizeListener(
        this.$refs[componentRef].$el,
        this.chm__debounce(100, () => {
          requestAnimationFrame(secs => {
            if (this.$refs.highcharts) {
              this.$refs.highcharts.chart.reflow();
            }
          });
        })
      );
    },
    chm__disableDateMethod: function(time) {
      const currDate = time;
      const paramObj = {
        dateRange: this.chm__getDateObjArrOfCultureDateRange,
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    chm__initDateRangeWithMomentObj(dateObjArr) {
      const dates = dateObjArr.map(dateItem => {
        return this.dhm__formatByTZOnUTCISOWithLocale(
          this.dhm__dateUtilsLib.formatTZToISO(
            dateItem,
            this.getUserTimeZoneString
          ),
          this.chm__dateFormat
        );
      });
      this.$set(this.dateRange, 0, dates[0]);
      this.$set(this.dateRange, 1, dates[1]);
      this.handleValueChange(this.dateRange);
    },
    chm__initChartStrings() {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    },
    chm__initChartLang() {
      this.chm__initChartStrings();
      this.chm__setAxisText();
      this.chm__initChart();
    },
    chm__setAxisText() {
      this.chartOptions.xAxis.title.text = this.$t(this.chm__xAxisTextKey);
      this.chartOptions.yAxis.title.text = this.$t(this.chm__yAxisTextKey);
    },
    chm__initAxisTextKeys(chm__xAxisTextKey, chm__yAxisTextKey) {
      this.chm__xAxisTextKey = chm__xAxisTextKey;
      this.chm__yAxisTextKey = chm__yAxisTextKey;
    }
  }
};
export default chartMixin;
