<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: moonPhase.vue
Description: This file contians UI used to show moon phase. It is being used in ponds page of overview section
-->
<template>
  <el-row class="moon-phase-container">
    <el-col class="col-pond-details">
      <el-row>
        <div type="flex" justify="center" style="display: flex">
          <el-tooltip
            popperClass="moon-phase-container-el-tooltip__popper"
            effect="light"
            v-for="phase in moonPhases"
            :key="phase.phase"
            :content="phasesTranslation[phase.moon_phase]"
          >
            <el-row tag="span" class="moon-phase">
              <el-col
                tag="span"
                :class="`moon-phase-shape--${phase.moon_phase}`"
              ></el-col>

              <el-col tag="span" class="moon-phase-date">
                <div style="width: max-content">
                  {{ getFormatDate(phase.date) }}
                </div>
              </el-col>
            </el-row>
          </el-tooltip>
        </div>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import thirdPartyService from "@/services/ThirdPartyService";
import { mapGetters } from "vuex";
// import dateUtils from "@/utils/dateUtils";
export default {
  mixins: [datesHandlerMixin],
  data: function () {
    return {
      moonPhases: [],
      loading: false,
      moonPhasesNames: [
        "new-moon",
        "waxing-crescent-moon",
        "quarter-moon",
        "waxing-gibbous-moon",
        "full-moon",
        "waning-gibbous-moon",
        "last-quarter-moon",
        "waning-crescent-moon"
      ]
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserTimeZoneString: "getUserTimeZoneString",
      getPreferredUnits: "getPreferredUnits"
    }),
    phasesTranslation: function () {
      return {
        FULL_MOON: this.$t("Comn_full_moon"),
        FIRST_MOON: this.$t("Comn_first_quarter"),
        NEW_MOON: this.$t("Comn_new_moon"),
        LAST_MOON: this.$t("Comn_last_quarter")
      };
    }
  },
  async mounted() {
    this.initComponent();
  },
  methods: {
    async initComponent() {
      const res = await thirdPartyService.getLunarPhasesDetails();
      this.moonPhases = res.data.lunar_phases.sort((a, b) =>
        this.dhm__dateUtilsLib.dateComparator(a.date, b.date)
      );
      console.log(this.moonPhases);
    },
    getFormatDate(date) {
      return this.dhm__formatByTZOnUTCISOWithLocale(
        date,
        this.upm__getFormatDateString
      );
    }
  }
};
</script>
<style lang="scss">
.moon-phase-container {
  .col-pond-details span.moon-phase-date {
    @include responsiveProperty(
      font-size,
      $app_font_size_v_medium,
      $app_font_size_small,
      $app_font_size_1
    );
    text-transform: capitalize;
  }
  .col-pond-details {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 13px;
    }
    span:last-child {
      color: #3f98cb;
    }
  }
  .col-pond-details:last-child {
    border-right: unset;
  }
  .el-row.moon-phase:nth-child(n-1) {
    margin-right: 8px;
  }
  .el-col[class*="moon-phase-shape"] {
    height: 18px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .moon-phase-shape--NEW_MOON {
    background-image: url("../../assets/moon-phase-0.svg");
  }
  .moon-phase-shape--FIRST_MOON {
    background-image: url("../../assets/moon-phase-2.svg");
  }
  .moon-phase-shape--FULL_MOON {
    background-image: url("../../assets/moon-phase-4.svg");
  }
  .moon-phase-shape--LAST_MOON {
    background-image: url("../../assets/moon-phase-6.svg");
  }
}

.moon-phase-container-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}
// .moon-phase-container-el-tooltip__popper[x-placement^="bottom"] {
//   margin-top: 11px;
// }
.moon-phase-container-el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #606266 !important;
  top: -7px;
}
</style>
