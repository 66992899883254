<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondAwgBiomassGraph.vue
Description: This file is the chart component that displays the abw and weekly growth as bar charts, biomass estimate, farm biomass, survival estimate and farm survival as line charts. Here x axis represents days of culture.The left y axis represents weight of the shrimp in grams and right y axis represents total biomass in the pond in kilograms and survival percentage.
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="pond-awg-biomass-graph"
  >
    <layout-toolbar slot="header">
      <p class="card-title">
        {{ $t("Comn_growth") }} vs {{ $t("Comn_biomass") }}
      </p>
      <div class="filler"></div>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import { AbwBiomassGraphConfig } from "./chartOptions";
import filtersMixin from "@/mixins/filtersMixin.js";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function () {
    return {
      abwdata: [],
      weeklygrowth: [],
      farmbiomass: [],
      estimatebiomass: [],
      dateRange: [],
      params: {
        from_date: "",
        to_date: ""
      },
      chartOptions: { ...AbwBiomassGraphConfig },
      loading: false
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getSelectedpondAbwVsBiomassGraphData:
        "getSelectedpondAbwVsBiomassGraphData"
    }),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName",
      getUserCountryCode: "getUserCountryCode",
      getPreferredUnits: "getPreferredUnits"
    }),
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.chm__getDateObjArrOfCultureDateRange;
    }
  },
  mounted() {
    this.chm__registerResizeObserver("pond-awg-biomass-graph");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("pond-awg-biomass-graph");
  },
  methods: {
    ...mapActions("pondDetails", {
      pondAbwVsBiomassGraphData: "pondAbwVsBiomassGraphData"
    }),
    async handleValueChange(currentSelectedValues) {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent();
    },
    chm__initChart() {
      const graphData = this.$lodash
        .cloneDeep(this.getSelectedpondAbwVsBiomassGraphData)
        .sort((a, b) => a.day_of_culture - b.day_of_culture);
      if (graphData.length === 0) {
        this.chartOptions.series = [];
        return;
      }
      const abwSeriesData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: ele.abw
      }));
      const growthSeriesData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: ele.growth
      }));
      const farmBiomassData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: this.upm__convertUnits(
          ele.farm_biomass,
          this.upm__backendUnits.biomass_units,
          this.getPreferredUnits.biomass_units
        )
      }));
      const estimatedBiomassData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: this.upm__convertUnits(
          ele.biomass_estimate,
          this.upm__backendUnits.biomass_units,
          this.getPreferredUnits.biomass_units
        )
      }));
      const survivalEstimateData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: ele.survival_estimate
      }));
      const farmSurvivalData = graphData.map((ele) => ({
        x: ele.day_of_culture,
        y: ele.farm_survival
      }));
      this.initChartSeries({
        estimatedBiomassData,
        farmBiomassData,
        farmSurvivalData,
        abwSeriesData,
        growthSeriesData,
        survivalEstimateData
      });
    },
    initChartSeries({
      estimatedBiomassData,
      farmBiomassData,
      farmSurvivalData,
      abwSeriesData,
      growthSeriesData,
      survivalEstimateData
    }) {
      this.chartOptions.series = [];
      [
        {
          name: "ABW",
          type: "column",
          yAxis: 0,
          data: abwSeriesData,
          tooltip: {
            valueSuffix: " g"
          },
          color: "#7394cb"
        },
        {
          name: this.$t("Comn_abw_or_growth"),
          type: "column",
          yAxis: 0,
          data: growthSeriesData,
          tooltip: {
            valueSuffix: " g"
          },
          color: "#90ed7d"
        },
        {
          name: this.$t("Comn_biomass_estimate"),
          type: "line",
          yAxis: 1,
          data: estimatedBiomassData,
          marker: {
            enabled: true
          },
          tooltip: {
            valueSuffix: ` ${this.getPreferredUnits.biomass_units}`
          },
          color: "#541743"
        },
        {
          name: this.$t("Comn_farm_biomass"),
          type: "line",
          yAxis: 1,
          data: farmBiomassData,
          marker: {
            enabled: true
          },
          tooltip: {
            valueSuffix: ` ${this.getPreferredUnits.biomass_units}`
          },
          color: "#f79431"
        },
        {
          name: this.$t("Comn_survival_estimate"),
          type: "line",
          yAxis: 1,
          data: survivalEstimateData,
          marker: {
            enabled: true
          },
          tooltip: {
            valueSuffix: " %"
          },
          color: "#48C9B0",
          visible: false
        },
        {
          name: this.$t("Comn_farm_survival"),
          type: "line",
          yAxis: 1,
          data: farmSurvivalData,
          marker: {
            enabled: true
          },
          tooltip: {
            valueSuffix: " %"
          },
          color: "#D98880",
          visible: false
        }
      ].forEach((seriesItem) => {
        this.chartOptions.series.push(seriesItem);
      });
    },
    chm__setAxisText() {
      this.chartOptions.xAxis[0].title.text = this.$t(this.xAxisTextKey);
      this.chartOptions.yAxis[0].title.text = this.$t(
        this.chm__yAxisTextKey[0]
      );
      this.chartOptions.yAxis[1].title.text = `${this.$t(
        this.chm__yAxisTextKey[1]
      )}(${this.getPreferredUnits.biomass_units})/${this.$t(
        this.chm__yAxisTextKey[2]
      )}`;
    },
    async initComponent() {
      this.loading = true;
      try {
        await this.pondAbwVsBiomassGraphData();
        this.pondSlotFeedLogs = this.getPondScheduleGraphSlotWiseFeed;
        this.chm__initAxisTextKeys("Comn_doc_days", [
          "Comn_weight_g",
          "Comn_biomass",
          "Comn_survival_percentage"
        ]);
        this.chm__initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pond-hourly-graph {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
</style>
