<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondDetails.vue
Description:This file contains the combination of components like pondCharts,pondFeedTab,pondInputTab,pondABWTab,pondLabTestsTab,pondStStatisticsTab
-->
<template>
  <layout-pondlogs
    class="pond-details"
    headerType="custom"
    v-loading="loading"
    element-loading-background="white"
  >
    <template v-if="selectedPondDetails">
      <div slot="header">
        <ponds-select-with-details :key="pondId"></ponds-select-with-details>
      </div>
      <el-container
        class="pond-details-body"
        direction="vertical"
        :slot="
          ['pondABWTab'].includes(computedComponentName)
            ? 'layout-pondlogs-main'
            : 'layout-pondlogs-scrollable-main'
        "
      >
        <layout-toolbar>
          <er-top-route-nav
            type="tabs"
            class="nav-pond-details-top"
            :items="fitlerTabItems"
          ></er-top-route-nav>
          <div class="filler"></div>
          <portal-target name="pond-details-top-nav"></portal-target>
        </layout-toolbar>
        <portal to="rightdestination">
          <moonPhase />
        </portal>
        <transition name="fade">
          <component
            v-if="!loading"
            :key="`${pondId} ${getCultureID}`"
            :is="
              getCultureID !== undefined ? computedComponentName : 'noCultures'
            "
            :tabData="fitlerTabItems[computedComponentName]"
          ></component>
        </transition>
      </el-container>
    </template>
    <div v-if="!selectedPondDetails && !loading" slot="header">
      <er-no-ponds></er-no-ponds>
    </div>
  </layout-pondlogs>
</template>

<script>
import pondCharts from "@/components/pond/pondCharts";
import pondsSelectWithDetails from "@/components/pond/pondsSelectWithDetails";
import pondInputTab from "@/components/pond/pondInputTab";
import pondLabTestsTab from "@/components/pond/pondLabTestsTab";
import pondFeedTab from "@/components/pond/pondFeedTab";
import pondABWTab from "@/components/pond/pondABWTab";
import noCultures from "@/components/errorHandlers/noCultures";
import pondStStatisticsTab from "@/components/pond/pondStStatisticsTab";
import moonPhase from "@/components/pond/moonPhase";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import { mapGetters, mapActions } from "vuex";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
const tabToPermission = {
  pondCharts: {
    name: "pondCharts",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES],
      UPDATE: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    }
  },
  pondFeedTab: {
    name: "pondFeedTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_DEVICES],
      UPDATE: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_DEVICES],
      DELETE: [permissions.MANAGE_CULTURE]
    }
  },
  pondInputTab: {
    name: "pondInputTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_MEDICINE]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_MEDICINE],
      UPDATE: [permissions.MANAGE_MEDICINE],
      DELETE: [permissions.MANAGE_MEDICINE]
    }
  },
  pondABWTab: {
    name: "pondABWTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_ABW]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_ABW],
      UPDATE: [permissions.MANAGE_ABW],
      DELETE: [permissions.MANAGE_ABW]
    }
  },
  pondLabTestsTab: {
    name: "pondLabTestsTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_LAB_TESTS]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_LAB_TESTS],
      UPDATE: [permissions.MANAGE_LAB_TESTS],
      DELETE: [permissions.MANAGE_LAB_TESTS]
    }
  },
  pondStStatisticsTab: {
    name: "pondStStatisticsTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_SCHEDULES]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_SCHEDULES],
      UPDATE: [permissions.MANAGE_SCHEDULES]
    }
  }
};

export default {
  mixins: [errorHandlerMixin, redirectsMixin],
  name: "PondDetails",
  components: {
    pondCharts,
    pondsSelectWithDetails,
    pondFeedTab,
    pondABWTab,
    pondInputTab,
    pondLabTestsTab,
    pondStStatisticsTab,
    moonPhase,
    noCultures
  },
  data: function() {
    return {
      componentName: "pond-charts",
      pondsObj: {},
      loading: true
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserLocationsObj: "getUserLocationsObj"
    }),
    ...mapGetters("pondDetails", {
      getPondGroupByStatus: "getPondGroupByStatus",
      getPondIdPondMap: "getPondIdPondMap",
      pondId: "getSelectedPondId",
      getCultureID: "getCultureID",
      getSelectedPondWithDeviceDetails: "getSelectedPondWithDeviceDetails"
    }),
    selectedPondDetails() {
      return this.PondsObj[this.pondId];
    },
    PondsObj() {
      return this.$store.getters["pond/getPondsObj"];
    },
    location() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    locationId() {
      return this.location._id;
    },
    computedComponentName: function() {
      return this.$route.query.tab;
    },
    fitlerTabItems: function() {
      return this.$gblUAMFilterItemsUserCanView(this.tabLabels);
    },
    tabLabels() {
      return {
        pondCharts: {
          label: this.$t("Comn_dashboard"),
          ...tabToPermission.pondCharts
        },
        pondFeedTab: {
          label: this.$t("Comn_feed"),
          ...tabToPermission.pondFeedTab
        },
        pondInputTab: {
          label: this.$t("Comn_inputs"),
          ...tabToPermission.pondInputTab
        },
        pondABWTab: {
          label: this.$t("ABW_abw"),
          ...tabToPermission.pondABWTab
        },
        pondLabTestsTab: {
          label: this.$t("LabTst_lab_tests"),
          ...tabToPermission.pondLabTestsTab
        },
        pondStStatisticsTab: {
          label: `${this.$t("Comn_shrimptalk")} ${this.$t("Comn_response")}`,
          ...tabToPermission.pondStStatisticsTab
        }
      };
    },
    getInitPond() {
      if (
        !this.getPondGroupByStatus ||
        (!this.getPondGroupByStatus.ACTIVE.length &&
          !this.getPondGroupByStatus.INACTIVE.length)
      ) {
        return undefined;
      }
      return [
        this.getPondGroupByStatus.ACTIVE,
        this.getPondGroupByStatus.INACTIVE
      ].find((x) => x.length)[0];
    }
  },
  watch: {
    pondId: function(newPondId) {
      this.changeUrl(newPondId);
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((vm) => {
      const component = vm;
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/pond-details",
          query: {
            pondId: to.query.pondId,
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },
  async mounted() {
    await this.initComponent("INIT");
  },
  methods: {
    ...mapActions("pondDetails", {
      changeSelectedPondId: "changeSelectedPondId",
      changePondWithDeviceDetails: "changePondWithDeviceDetails",
      changeSelectedTab: "changeSelectedTab"
    }),
    changeUrl(pondId) {
      if (
        typeof pondId !== "undefined" &&
        pondId !== this.$route.query.pondId &&
        pondId !== ""
      ) {
        this.rdm__navigateToPathWithLocationId({
          path: `/user/pond-details`,
          query: {
            pondId: pondId,
            tab: this.computedComponentName
          }
        });
      }
    },
    async initComponent(event) {
      try {
        this.loading = true;
        await this.$store.dispatch("pond/fetchAllPonds", {
          location_id: this.location._id,
          get_all: true,
          order_by: "title",
          status: ["ACTIVE", "INACTIVE"]
        });
        this.pondsObj = this.PondsObj;
        let pondId = this.$route.query.pondId;
        const doRouteParamHasPondId = this.$route.query.pondId !== undefined;
        const doLocationHasPondIdInRouteParams =
          doRouteParamHasPondId && this.PondsObj[pondId];
        pondId = doLocationHasPondIdInRouteParams ? pondId : undefined;
        if (!pondId && this.getInitPond) {
          pondId = this.getInitPond.pond_id;
        }
        if (pondId) {
          await this.changeSelectedPondId(pondId);
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pond-details {
  height: 100%;
  .el-main {
    overflow: hidden;
  }
  .pond-details-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
  }
  .el-menu--horizontal.nav-pond-details-top {
    margin-bottom: 7px;
  }
  .pond__details__tabs {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );

    display: flex;
  }
  .no-data {
    height: 34rem;
  }
  .card-pond-charts {
    .el-card__body {
      padding: 5px;
    }
  }
  .pond-details-scroll {
    height: 100%;
  }
  .pond-details-scroll-view {
    padding-right: 10px;
  }
  .pond-details-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
}
</style>
