<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondFeedTab.vue
Description: This file contains UI components used to display feed input given to a pond. It contains data showing the feed input records
-->
<template>
  <el-row class="pond-feed-tab">
    <layout-toolbar class="second-label-container">
      <div>
        <er-date-picker
          :key="selectedPondDetails._id"
          v-model="dateRangeSelected"
          value-format="yyyy-MM-dd"
          :timeZoneString="getUserTimeZoneString"
          :format="upm__getFormatDateString"
          type="daterange"
          :clearable="false"
          :picker-options="pickerOptions"
          :availableInterval="availableInterval"
          @change="handelDatePickerChange"
        ></er-date-picker>
      </div>
      <er-select
        v-model="selectedFeedType"
        :showSelectAll="true"
        collapse-tags
        multiple
        size="mini"
        class="feed_select"
        :placeholder="$t('Comn_feed_type')"
        popper-class="pm-dropdown"
        @change="handelFeedTypeChange"
      >
        <el-option
          v-for="record in getFeedTypes"
          :key="record"
          :value="record"
          :label="record"
          >{{ record }}
        </el-option>
      </er-select>
      <div class="filler"></div>
      <div style="display: flex">
        <div class="total__feed__button">
          <div class="total__feed__button__label">{{ $t("total_feed") }}</div>
          <div class="highest__feed__button__value">
            <p
              v-upm__units.truncateThousandStr="
                getFeedDispensedAndUnits(getTotalFeed)
              "
            ></p>
          </div>
        </div>
        &nbsp;&nbsp;
        <div class="highest__feed__button">
          <div class="highest__feed__button__label">
            {{ $t("Comn_highest_feed") }}
          </div>
          <div class="highest__feed__button__value">
            <p
              v-upm__units.truncateThousandStr="
                getFeedDispensedAndUnits(getHighestFeed)
              "
            ></p>
          </div>
        </div>
        &nbsp;&nbsp;
        <er-download-drop-down
          :download-types="['EXCEL', 'PDF']"
          @download-action="handleDownloadAction"
        ></er-download-drop-down>
        &nbsp;&nbsp;
        <er-button
          btnType="print"
          @click="handlePrintBtnClick"
          :showLabel="true"
          :showIcon="true"
        ></er-button>
      </div>
    </layout-toolbar>
    <el-row class="pond-feed-table">
      <er-card v-loading="loading" element-loading-background="white">
        <div class="loading_class" v-if="loading"></div>
        <er-data-tables
          ref="table"
          :el-table-props="tableProps"
          v-show="!loading"
          :tableData="tableData"
          uniqueId="pond-feed-tab"
          :merge="{ startColumnNumber: 1, endColumnNumber: 2 }"
          :columns="tableColumns"
          :action-column="false"
          type="white-header-table"
          size="mini"
          :key="$i18n.locale"
        >
          <template v-slot:doc="{ row }">{{ row.data.doc }}</template>
          <template v-slot:event_date="{ row }">{{
            getEventDate(row.data.date)
          }}</template>
          <template v-slot:feed_name="{ row }">{{
            row.data.feed_name
          }}</template>
          <template v-slot:feed_dispensed="{ row }">
            <p
              v-upm__units.digitPrecision="
                getFeedDispensed(row.data.feed_dispensed)
              "
            ></p>
          </template>
          <template v-slot:net_consumed="{ row }">
            <p
              v-upm__units.digitPrecision="
                getFeedDispensed(row.data.net_consumed)
              "
            ></p>
          </template>
          <template v-slot:actions="{ row }">
            <el-row type="flex" justify="start" align="middle">
              <er-button
                type="text"
                size="mini"
                btnType="edit"
                :showLabel="true"
                :showIcon="true"
                :disabled="disabledBtn"
                @click="handleEditMFeedRecord(row.data)"
              ></er-button>
              <el-divider direction="vertical"></el-divider>
              <er-button
                type="text"
                size="mini"
                btnType="delete"
                :showLabel="true"
                :showIcon="true"
                :disabled="disabledBtn"
                @click="handleDeleteClick(row.data)"
              ></er-button>
            </el-row>
          </template>
        </er-data-tables>
      </er-card>
    </el-row>

    <er-dialog
      :visible.sync="dialogVisible"
      width="28%"
      :close-on-click-modal="false"
      custom-class="pondFeed_dialog"
      :title="`${$t('Comn_update')} ${$t('Comn_manual_feed')}`"
    >
      <el-form
        size="small"
        :model="editManualFeedRec"
        :hide-required-asterisk="true"
      >
        <el-form-item
          :label="$t('Comn_pond_name')"
          prop="pond_id"
          :required="true"
        >
          <el-col :span="20">
            <er-select
              filterable
              size="small"
              v-model="editManualFeedRec.pond_id"
              value-key="_id"
              :disabled="true"
            >
              <el-option
                v-for="(pond, pond_id) in PondsObj"
                :key="pond_id"
                :value="pond_id"
                :label="pond.title"
              ></el-option>
            </er-select>
          </el-col>
        </el-form-item>

        <el-form-item
          :label="$t('Comn_feed_name')"
          prop="feed_id"
          :required="true"
        >
          <el-col :span="20">
            <er-select
              size="small"
              filterable
              v-model="editManualFeedRec.feed_id"
              value-key="_id"
            >
              <el-option
                v-for="(rsc, rsc_id) in resourcesObj"
                :key="rsc_id"
                :value="rsc_id"
                :label="getFeedName(rsc)"
              ></el-option>
            </er-select>
          </el-col>
        </el-form-item>

        <el-form-item
          :label="$t('PM_dispensed_feed')"
          prop="feed_dispensed"
          :required="true"
        >
          <el-col :span="20">
            <el-input-number
              :controls="false"
              size="small"
              :min="0"
              :precision="2"
              v-model="editManualFeedRec.feed_dispensed"
            ></el-input-number>
          </el-col>
        </el-form-item>
      </el-form>

      <layout-toolbar slot="footer" justify="center">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="decideActionAndPrepPayload({}, 'save')"
          :disabled="isReadOnly"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="closeDialogBox"
          :disabled="isReadOnly"
        ></er-button>
      </layout-toolbar>
    </er-dialog>
  </el-row>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin.js";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import exportFileDownload from "@/mixins/exportFileDownload";
import printJS from "print-js";
import notificationMixin from "@/mixins/notificationMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  props: ["tabData"],
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    notificationMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    exportFileDownload
  ],
  data: function() {
    return {
      data: [],
      selectedFeedType: [],
      dateRangeSelected: [],
      dialogVisible: false,
      loading: false,
      total_manual_feed: 0,
      highest_feed: 0,
      editManualFeedRec: {
        pond_id: "",
        feed_id: "",
        dispensed_feed: ""
      },
      tableProps: {
        height: "calc(100vh - var(--height-gutter))"
      }
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      pondId: "getSelectedPondId",
      getManualFeedData: "getManualFeedData",
      getCurrentCulture: "getCurrentCulture",
      getRunningCultureForSelectedPond: "getRunningCultureForSelectedPond",
      getArrPrevCulturesForSelectedPond: "getArrPrevCulturesForSelectedPond"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation",
      getUserCountryName: "getUserCountryName",
      getUserCountryCode: "getUserCountryCode"
    }),
    disabledBtn() {
      return this.isReadOnly || !this.getCurrentCulture.is_running;
    },

    availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    getFeedDispensedAndUnits() {
      return (feed_dispensed) => {
        const totalFeedandUnits = {
          value: feed_dispensed || 0,
          previousunits: this.upm__backendUnits.feed_units,
          units: this.upm__feedUnits,
          modifiersParams: {
            truncateThousandStr: [0]
          }
        };
        return totalFeedandUnits;
      };
    },
    getFeedDispensed() {
      return (feed_dispensed) => {
        const totalFeedandUnits = {
          value: feed_dispensed || 0,
          previousunits: this.upm__backendUnits.feed_units,
          units: this.upm__feedUnits,
          modifiersParams: { digitPrecision: [2] },
          displayFormatter: () => {
            return ``;
          }
        };
        return totalFeedandUnits;
      };
    },

    getFeedTypes() {
      return [...new Set(this.data.map(({ feed_name }) => feed_name))];
    },
    location: function() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    PondsObj: function() {
      return this.getPondIdPondMap;
    },
    selectedPondDetails() {
      return this.PondsObj[this.pondId];
    },
    resourcesObj: function() {
      return this.$store.getters["resource/getObjResources"];
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.doc.label,
          dataKey: "doc",
          emptyValue: "--",
          name: "doc",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: this.tableColumns.event_date.label,
          dataKey: (value) => {
            return this.dhm__formatByTZOnUTCWithLocale(
              value.date,
              this.upm__getFormatDateString
            );
          },
          emptyValue: "--",
          name: "date",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: this.tableColumns.feed_name.label,
          dataKey: "feed_name",
          emptyValue: "--",
          name: "feed_name",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: this.tableColumns.feed_dispensed.label,
          dataKey: "feed_dispensed",
          emptyValue: "--",
          name: "feed_dispensed",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: this.tableColumns.net_consumed.label,
          dataKey: "net_consumed",
          emptyValue: "--",
          name: "net_consumed",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        }
      ];
    },

    tableColumns: function() {
      return {
        doc: {
          prop: "doc",
          label: this.$t("Comn_doc"),
          ispdf: true,
          sortable: true,
          minWidth: 60
        },
        event_date: {
          prop: "date",
          label: this.$t("Medicines_event_date"),
          ispdf: true,
          sortable: true,
          minWidth: 100
        },
        feed_name: {
          prop: "feed_name",
          label: this.$t("Comn_feed_name"),
          ispdf: true,
          sortable: true,
          minWidth: 100
        },
        feed_dispensed: {
          prop: "feed_dispensed",
          label: this.$t("dispensed_feed_manual"),
          sub_label: `(${this.$t(this.upm__feedUnits)})`,
          ispdf: true,
          sortable: true,
          minWidth: 100
        },
        net_consumed: {
          prop: "net_consumed",
          label: this.$t("Comn_net_feed"),
          sub_label: `(${this.$t(this.upm__feedUnits)})`,
          ispdf: true,
          sortable: true,
          minWidth: 100
        },
        actions: {
          label: this.$t("Comn_actions"),
          ispdf: false,
          minWidth: 80
        }
      };
    },
    groupByFeed: function() {
      return this.data.reduce((acc, curr) => {
        if (!curr) return acc;
        acc[curr.feed_name] =
          (acc[curr.feed_name] || 0) +
          (curr || { feed_dispensed: 0 }).feed_dispensed;
        return acc;
      }, {});
    },
    arrOfGroupedFeed: function() {
      return Object.keys(this.groupByFeed).sort(
        this.$commonUtils.alphaNumericComparator
      );
    },
    objOfPondManualFeedRec() {
      return this.getManualFeedData.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    },
    getCultureDateRange() {
      return [
        this.getCurrentCulture.start_date,
        this.getCurrentCulture.end_date
      ];
    },
    pickerOptions: function() {
      return {
        disabledDate: (time) => {
          const endDate = this.dhm__dateUtilsLib.endOfDay(
            this.dhm__dateUtilsLib.utcToZonedTime(
              this.dhm__dateUtilsLib.add(new Date(), { days: 0 }),
              this.getUserTimeZoneString
            )
          );
          const startDate = this.dhm__dateUtilsLib.startOfDay(
            this.dhm__dateUtilsLib.subtract(new Date(), {
              days: this.getCurrentCulture.doc
            })
          );
          const currTime = time;
          const paramObj = {
            dateRange: [startDate, endDate],
            date: currTime,
            timeZone: this.getUserTimeZoneString,
            actionsOnDate: [],
            actionsOnDateRangeItem: []
          };
          return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
        }
      };
    },
    tableData() {
      return this.data.filter(({ feed_name }) =>
        this.selectedFeedType.includes(feed_name)
      );
    },
    getTotalFeed() {
      return this.tableData.reduce(
        (n, { feed_dispensed }) => n + feed_dispensed,
        0
      );
    },
    getHighestFeed() {
      return Math.max(...this.tableData.map((x) => x.feed_dispensed), 0);
    }
  },
  async mounted() {
    this.initDate();
    await this.initComponent();
  },
  methods: {
    ...mapActions({
      fetchAllResources: "resource/fetchAllResources",
      fetchManualFeedData: "pondDetails/fetchManualFeedData",
      deleteFeedRecord: "pond/deleteFeedRecord",
      saveManualFeedingRecords: "pond/saveManualFeedingRecords"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getFeedName(resource) {
      return resource.type === "FEED" && resource.feed_type
        ? `${resource.name} - ${resource.feed_type} `
        : resource.name;
    },
    handelFeedTypeChange() {
      this.mixPanelEventGenerator({
        eventName: "Ponds - Feed - Feed Type - Dropdown"
      });
    },
    handelDatePickerChange() {
      this.initComponent();
      this.mixPanelEventGenerator({ eventName: "Ponds - Feed - Date Filter" });
    },
    getEventDate(date) {
      return this.dhm__formatByTZOnUTCWithLocale(
        date,
        this.upm__getFormatDateString
      );
    },
    handlePrintBtnClick: function() {
      printJS({
        printable: this.$lodash.cloneDeep(this.data).map((x) => {
          x.date = this.dhm__formatByTZOnUTCWithLocale(
            x.date,
            this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR
          );
          return x;
        }),
        properties: Object.values(this.tableColumns)
          .filter((x) => x.prop)
          .map((x) => ({
            field: x.prop,
            displayName: x.label
          })),
        type: "json",
        documentTitle: `${this.selectedPondDetails.title} ${this.$t(
          "Comn_manual_feeding"
        )}`
      });
      this.mixPanelEventGenerator({ eventName: "Ponds - Feed - Print" });
    },
    initDate() {
      const endDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.end_date,
        "yyyy-MM-dd"
      );
      const startDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.start_date,
        "yyyy-MM-dd"
      );
      console.log(this.getCurrentCulture);
      this.dateRangeSelected = [startDate, endDate];
    },
    async initComponent() {
      try {
        this.loading = true;
        await Promise.all([
          this.fetchAllResources({
            type: "FEED",
            // include_deleted: true,
            location_id: this.location._id,
            get_all: true
          }),
          this.fetchManualFeedData({
            params: {
              get_all: true,
              from_date: `${this.dateRangeSelected[0]}T00:00:00.000Z`,
              to_date: `${this.dateRangeSelected[1]}T00:00:00.000Z`
            },
            commitToStore: true
          })
        ]);
        const cultivation_date = `${this.dateRangeSelected[0]}T00:00:00.000Z`;
        this.data = this.getManualFeedData
          .map((rec) => {
            return rec.feeds.map(({ feed_dispensed, feed_id }, index) => {
              const doc =
                this.dhm__dateUtilsLib.differenceInDays(
                  this.dhm__castUserUTCToUTCDateObj(rec.date),
                  this.dhm__castUserUTCToUTCDateObj(cultivation_date)
                ) + 1;
              const feed_name = feed_id.feed_type
                ? `${feed_id.name} - ${feed_id.feed_type}`
                : feed_id.name;
              return {
                feed_name: feed_name,
                feed_id: feed_id._id,
                feed_dispensed: feed_dispensed,
                date: rec.date,
                pond_id: rec.pond_id,
                doc: doc,
                feed_index: index,
                record_id: rec._id
              };
            });
          })
          .flat(1);
        this.data = this.data.sort((a, b) => a.doc - b.doc);
        let net_consumed = 0;
        this.data = this.data.map((x) => {
          net_consumed += x.feed_dispensed;
          x.net_consumed = net_consumed;
          return x;
        });
        this.total_manual_feed = net_consumed;
        // this.highest_feed = Math.max(
        //   ...this.data.map((x) => x.feed_dispensed),
        //   0
        // );
        this.data = this.data.sort(
          (a, b) => b.doc - a.doc || b.net_consumed - a.net_consumed
        );

        this.selectedFeedType = this.getFeedTypes;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.table) {
            console.log(this.$refs.table.$refs.dataTables.$refs.elTable);
            this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleEditMFeedRecord(editManualFeedRec) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisible = true;
        this.editManualFeedRec = JSON.parse(JSON.stringify(editManualFeedRec));
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async handleDeleteClick(rowData) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.decideActionAndPrepPayload(rowData, "delete");
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleDownloadAction(downloadType) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (downloadType === "EXCEL") {
          this.exportAsExcel();
        } else if (downloadType === "PDF") {
          this.exportAsPDF();
        }
        this.mixPanelEventGenerator({ eventName: "Ponds - Feed - Download" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    exportAsPDF() {
      this.exp__Download(
        this.tableData.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(b.date, a.date)
        ),
        this.downloadColumns,
        "pdf"
      );
    },
    exportAsExcel() {
      this.exp__Download(
        this.tableData.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(b.date, a.date)
        ),
        this.downloadColumns,
        "xcel"
      );
    },
    closeDialogBox() {
      this.dialogVisible = false;
    },
    async decideActionAndPrepPayload(manualFeedRecord, action) {
      this.loading = true;
      const actionRecord =
        action === "save" ? this.editManualFeedRec : manualFeedRecord;
      const feedName = actionRecord.feed_name;
      const record = this.$lodash.cloneDeep(
        this.objOfPondManualFeedRec[actionRecord.record_id]
      );
      let recordFeedsArr = record.feeds;
      recordFeedsArr = recordFeedsArr.map(({ feed_dispensed, feed_id }) => {
        return {
          feed_dispensed,
          feed_id: feed_id._id
        };
      });
      if (action === "save") {
        record.feed_dispensed -=
          recordFeedsArr[actionRecord.feed_index].feed_dispensed;
        record.feed_dispensed += actionRecord.feed_dispensed;
        recordFeedsArr[actionRecord.feed_index].feed_dispensed =
          actionRecord.feed_dispensed;
        recordFeedsArr[actionRecord.feed_index].feed_id = actionRecord.feed_id;
        record.feeds = recordFeedsArr;
        await this.saveMEditedDetails(record, feedName);
        this.mixPanelEventGenerator({
          eventName: "Ponds - Feed - Table Data Edit"
        });
      } else if (action === "delete") {
        if (recordFeedsArr.length > 1) {
          const recordToBeDel = recordFeedsArr[actionRecord.feed_index];
          record.feed_dispensed -= recordToBeDel.feed_dispensed;
          recordFeedsArr.splice(actionRecord.feed_index, 1);
          record.feeds = recordFeedsArr;
          await this.deleteMFeedRecord(record, feedName, "save");
        } else {
          await this.deleteMFeedRecord(record, feedName, "delete");
        }
        this.mixPanelEventGenerator({
          eventName: "Ponds - Feed - Table Data Delete"
        });
      }
      this.loading = false;
    },
    async deleteMFeedRecord(record, feedName, action) {
      this.loading = true;
      try {
        await this.nofm__deleteConfirmation(
          feedName,
          this.selectedPondDetails.title
        );

        if (action === "save") {
          await this.saveManualFeedingRecords([record]);
        } else {
          await this.deleteFeedRecord(record._id);
        }
        this.$notify.success({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Comn_data_del_success", {
            data_type: feedName,
            item: this.selectedPondDetails.title
          })
        });
        await this.initComponent();
      } catch (err) {
        if (err !== "cancel") {
          this.$notify.error({
            title: this.$t("failed"),
            message: this.$t("failed_to_complete_the_action_for_resource", {
              resource_name: feedName,
              action: this.$t("Comn_delete")
            })
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async saveMEditedDetails(payload, feedName) {
      try {
        await this.saveManualFeedingRecords([payload]);
        this.$notify.success({
          title: this.$t("Usrs_success_msg"),
          message: `${this.$t("PM_feeding_data_succe_updated_for", {
            item: feedName
          })}`
        });
        this.dialogVisible = false;
        await this.initComponent();
      } catch (err) {
        this.$notify.error({
          title: this.$t("failed"),
          message: this.$t("failed_to_complete_the_action_for_resource", {
            resource_name: feedName,
            action: this.$t("Comn_update")
          })
        });
      }
    }
  }
};
</script>

<style lang="scss">
.pond-feed-tab {
  .el-picker-panel__body {
    margin-left: 0 !important;
  }
  .second-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .er-button.el-button--default:not(.is-circle) {
      padding: 5px 15px;
    }
    .total__feed__button {
      min-width: max-content;
      margin-left: auto;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: #233a73;
      // height: 28px;
      color: #ffffff;
      .total__feed__button__label {
        @include normal-text;
        padding-right: 10px;
      }
    }
    .highest__feed__button {
      min-width: max-content;
      padding: 5px 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background-color: #f54e5e;
      color: #ffffff;
    }
    .highest__feed__button__value {
      @include normal-text;
    }

    .highest__feed__button__label {
      @include normal-text;
      padding-right: 10px;
    }
    .feed_select {
      @include responsiveProperty(width, 245px, 300px, 350px);
    }
  }
  .el-date-editor .el-range__close-icon {
    display: none;
  }
  .date-picker.el-range-editor.el-date-editor.el-input,
  .date-picker.el-range-editor.el-date-editor.el-input__inner {
    @include responsiveProperty(width, 210px, 250px, 270px);
    padding: 6px 10px;
  }
  .side-stats-content {
    // height: 15px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .el-input-number {
    width: 100%;
  }
  .er-button {
    @include responsiveProperty(height, 28px, 30px, 34px);
  }
  .el-input--mini .el-input__inner {
    @include responsiveProperty(height, 28px, 30px, 34px);
  }

  .el-select {
    .el-tag--mini {
      @include small-text;

      .el-select .el-tag__close {
        margin-top: -5px !important;
      }

      .el-tag__close.el-icon-close {
        top: 0px;
      }

      .el-select__tags-text:first-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include responsiveProperty(max-width, 145px, 180px, 220px);
        display: inline-block;
      }
      .el-select__tags-text:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include responsiveProperty(width, 15px, 20px, 25px);
        display: inline-block;
      }
    }
    //icons size
    .er-button.er-button--icon-text span > img,
    .er-button.er-button--icon-text span > span.material-icons-round,
    .er-button.er-button--icon-text span > span.material-icons-outlined {
      @include normal-text;
    }
  }
}
.pond-feed-table {
  @include responsiveProperty(--height-gutter, 270px, 290px, 300px);
  margin-top: 8px;
  .loading_class {
    @include responsiveProperty(height, 61.5vh, 66.5vh, 73vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
}

.pondFeed_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-form-item--small .el-form-item__content {
    @include normal-text;
    .el-input-number {
      text-align: left;
    }
    @include responsiveProperty(width, 210px, 300px, 350px);
  }
  .el-form-item--small .el-form-item__label {
    @include normal-text;
    .el-input-number {
      text-align: left;
    }
    @include responsiveProperty(min-width, 105px, 130px, 180px);
  }
}
</style>
